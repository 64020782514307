export const ONE_SECTION_REQUEST = 'ONE_SECTION_REQUEST'
export const ONE_SECTION_SUCCESS = 'ONE_SECTION_SUCCESS'
export const ONE_SECTION_FAILURE = 'ONE_SECTION_FAILURE'
export const ALL_SECTIONS_REQUEST = 'ALL_SECTIONS_REQUEST'
export const ALL_SECTIONS_SUCCESS = 'ALL_SECTIONS_SUCCESS'
export const ALL_SECTIONS_FAILURE = 'ALL_SECTIONS_FAILURE'
export const ADD_SECTION_REQUEST = 'ADD_SECTION_REQUEST'
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS'
export const ADD_SECTION_FAILURE = 'ADD_SECTION_FAILURE'
export const DEL_SECTION_REQUEST = 'DEL_SECTION_REQUEST'
export const DEL_SECTION_SUCCESS = 'DEL_SECTION_SUCCESS'
export const DEL_SECTION_FAILURE = 'DEL_SECTION_FAILURE'
export const UPD_SECTION_REQUEST = 'UPD_SECTION_REQUEST'
export const UPD_SECTION_SUCCESS = 'UPD_SECTION_SUCCESS'
export const UPD_SECTION_FAILURE = 'UPD_SECTION_FAILURE'
