import { createStore } from 'redux'

import rootReducer from './reducers'

const LOCAL_STORAGE_NAME = 'localData'

class PersistedStore {
  // Singleton property
  static DefaultStore

  // Accessor to the default instance of this class
  static getDefaultStore(middleware: any) {
    if (!PersistedStore.DefaultStore) {
      PersistedStore.DefaultStore = new PersistedStore(middleware)
    }

    return PersistedStore.DefaultStore
  }

  // Redux store
  _store

  // When class instance is used, initialize the store
  constructor(middleware: any) {
    this.initStore(middleware)
  }

  // Initialization of Redux Store
  initStore(middleware: any) {
    this._store = createStore(
      rootReducer,
      PersistedStore.loadState(),
      middleware,
    )
    this._store.subscribe(() => {
      PersistedStore.saveState(this._store.getState())
    })
  }

  // Getter to access the Redux store
  get store() {
    return this._store
  }

  // Loading persisted state from localStorage, no need to access
  // this method from the outside
  static loadState() {
    try {
      const serializedState = localStorage.getItem(LOCAL_STORAGE_NAME)
      if (serializedState === null) {
        return PersistedStore.initialState()
      }

      return JSON.parse(serializedState)
    } catch (err) {
      return PersistedStore.initialState()
    }
  }

  // Saving persisted state to localStorage every time something
  // changes in the Redux Store (This happens because of the subscribe()
  // in the initStore-method). No need to access this method from the outside
  static saveState(state) {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem(LOCAL_STORAGE_NAME, serializedState)
    } catch (err) {
      console.log(err)
    }
  }

  // Return whatever you want your initial state to be
  static initialState() {
    return {}
  }
}

export default PersistedStore
