import {
  ChangePasswordTypescript,
  LoginTypescript,
  RecoverySendEmailTypescript,
  UsersTypescript,
} from '../../../typescript'
import * as types from '../../types'

export function logoutRequest(): {
  type: string
} {
  return {
    type: types.LOGOUT_REQUEST,
  }
}

export function logoutSuccess(): {
  type: string
} {
  return {
    type: types.LOGOUT_SUCCESS,
  }
}

export function loginRequest(login: LoginTypescript): {
  type: string
  payload: LoginTypescript
} {
  return {
    type: types.LOGIN_REQUEST,
    payload: login,
  }
}

export function loginSuccess(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.LOGIN_SUCCESS,
    payload: data,
  }
}

export function loginFailure(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.LOGIN_FAILURE,
    payload: data,
  }
}

export function loginCreateTokenRequest(email: string): {
  type: string
  payload: string
} {
  return {
    type: types.LOGIN_CREATE_TOKEN_REQUEST,
    payload: email,
  }
}
export function loginCreateTokenReset(): {
  type: string
} {
  return {
    type: types.LOGIN_CREATE_TOKEN_RESET,
  }
}

export function loginCreateTokenSuccess(data): {
  type: string
  payload
} {
  return {
    type: types.LOGIN_CREATE_TOKEN_SUCCESS,
    payload: data,
  }
}

export function loginCreateTokenFailure(data: UsersTypescript): {
  type: string
  payload: UsersTypescript
} {
  return {
    type: types.LOGIN_CREATE_TOKEN_FAILURE,
    payload: data,
  }
}

// Change Password
export function changePasswordRequest(
  changePassword: ChangePasswordTypescript,
): {
  type: string
  payload: ChangePasswordTypescript
} {
  return {
    type: types.LOGIN_CHANGE_PASSWORD_REQUEST,
    payload: changePassword,
  }
}

export function changePasswordSuccess(data: ChangePasswordTypescript): {
  type: string
  payload: ChangePasswordTypescript
} {
  return {
    type: types.LOGIN_CHANGE_PASSWORD_SUCCESS,
    payload: data,
  }
}

export function changePasswordFailure(data: ChangePasswordTypescript): {
  type: string
  payload: ChangePasswordTypescript
} {
  return {
    type: types.LOGIN_CHANGE_PASSWORD_FAILURE,
    payload: data,
  }
}

export function changePasswordReset(): {
  type: string
} {
  return {
    type: types.LOGIN_CHANGE_PASSWORD_RESET,
  }
}

// Recovery Password Send Email
export function recoverySendEmailRequest(
  recoverySendEmail: RecoverySendEmailTypescript,
): {
  type: string
  payload: RecoverySendEmailTypescript
} {
  return {
    type: types.LOGIN_RECOVERY_SEND_EMAIL_REQUEST,
    payload: recoverySendEmail,
  }
}

export function recoverySendEmailSuccess(data: RecoverySendEmailTypescript): {
  type: string
  payload: RecoverySendEmailTypescript
} {
  return {
    type: types.LOGIN_RECOVERY_SEND_EMAIL_SUCCESS,
    payload: data,
  }
}

export function recoverySendEmailFailure(data: RecoverySendEmailTypescript): {
  type: string
  payload: RecoverySendEmailTypescript
} {
  return {
    type: types.LOGIN_RECOVERY_SEND_EMAIL_FAILURE,
    payload: data,
  }
}

export function recoverySendEmailReset(): {
  type: string
} {
  return {
    type: types.LOGIN_RECOVERY_SEND_EMAIL_RESET,
  }
}
