import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import {
  DashboardTemperatureTypescript,
  LastTemperatureReadTypescript,
  TemperatureTypescript,
} from '../../../typescript'
import * as TemperaturesActions from '../../actions/temperatures'
import * as types from '../../types'

const oneTemperatureRequisicao = () =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, 2000)
  })

let oneDashboardTemperatureApi: DashboardTemperatureTypescript[]
const oneDashboardTemperatureRequisicao = async (serialNumber: string) => {
  const query = `
  query {
    dashboardTemperatures(limit: ${60} serialNumberDevice: "${serialNumber}") {
      device {
        _id
        description
        serialNumber
        latitude
        longitude
        inputNameOne
        inputNameTwo
        inputNameThree
        temperatureNameOne
        temperatureNameTwo
        temperatureNameThree
        minimumRangeOne              
        maximumRangeOne
        minimumRangeTwo              
        maximumRangeTwo
        minimumRangeThree
        maximumRangeThree
        minimumAlertOne
        maximumAlertOne
        minimumAlertTwo
        maximumAlertTwo
        minimumAlertThree
        maximumAlertThree
        
      tActiveOne
      tActiveTwo
      tActiveThree

      }
      temperatures {
        _id
        deviceId
        timeStamp
        temperatureOne
        temperatureTwo
        temperatureThree
        inputOne
        inputTwo              
        latitude
        longitude
        minimumRangeOne              
        maximumRangeOne
        minimumRangeTwo              
        maximumRangeTwo
        minimumRangeThree
        maximumRangeThree
        minimumAlertOne
        maximumAlertOne
        minimumAlertTwo
        maximumAlertTwo
        minimumAlertThree
        maximumAlertThree
      }
      records
      openings
      minimumOne
      averageOne
      maximumOne
      minimumTwo
      averageTwo
      maximumTwo
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneDashboardTemperatureApi = data.data.dashboardTemperatures

    return true
  })
}

let allTemperaturesApi: DashboardTemperatureTypescript[]
const allTemperaturesRequisicao = async () => {
  await api({
    method: 'post',
    data: {
      query: `
        query {
          dashboardTemperatures(limit: ${60}) {
            device {
              _id
              description
              serialNumber
              latitude
              longitude
              inputNameOne
              inputNameTwo
              inputNameThree
              temperatureNameOne
              temperatureNameTwo
              temperatureNameThree
              minimumRangeOne              
              maximumRangeOne
              minimumRangeTwo              
              maximumRangeTwo
              minimumRangeThree
              maximumRangeThree
              minimumAlertOne
              maximumAlertOne
              minimumAlertTwo
              maximumAlertTwo
              minimumAlertThree
              maximumAlertThree
              tActiveOne
             tActiveTwo
             tActiveThree

            }
            temperatures {
              _id
              deviceId
              timeStamp
              temperatureOne
              temperatureTwo
              temperatureThree
              soilMoisture
              inputOne
              inputTwo              
              latitude
              longitude
              minimumRangeOne              
              maximumRangeOne
              minimumRangeTwo              
              maximumRangeTwo
              minimumRangeThree
              maximumRangeThree
              minimumAlertOne
              maximumAlertOne
              minimumAlertTwo
              maximumAlertTwo
              minimumAlertThree
              maximumAlertThree
            }
            records
            openings
            minimumOne
            averageOne
            maximumOne
            minimumTwo
            averageTwo
            maximumTwo
            minimumThree
            averageThree
            maximumThree
          }
        }
          `,
    },
  }).then(({ data }) => {
    allTemperaturesApi = data.data.dashboardTemperatures

    return true
  })
}

let lastTemperaturesReadApi: DashboardTemperatureTypescript[]
const lastTemperaturesRequisicao = async (
  dashboardTemperature: DashboardTemperatureTypescript,
) => {
  const query = `
        query {
          lastTemperaturesRead(find:{
            ${
              dashboardTemperature.device.idEconomicGroup !== undefined
                ? 'idEconomicGroup: "' +
                  dashboardTemperature.device.idEconomicGroup +
                  '"'
                : ''
            }
            ${
              dashboardTemperature.device.branchNumber !== undefined
                ? 'branchNumber: ' + dashboardTemperature.device.branchNumber
                : ''
            }
            ${
              dashboardTemperature.device.serialNumber !== undefined
                ? 'serialNumber: "' +
                  dashboardTemperature.device.serialNumber +
                  '"'
                : ''
            }
          }) {
            device {
              deviceType
              description
              serialNumber
              branchNumber
              latitude
              latitude
              latitude
              longitude
              inputNameOne
              inputNameTwo
              inputNameThree
              temperatureNameOne
              temperatureNameTwo
              temperatureNameThree
              economicGroupDetail{
               _id
               groupName
               groupNumber
             }
             tActiveOne
            tActiveTwo
            tActiveThree
            }
            deviceId
            timeStamp
            gpsTimeStamp
            temperatureOne
            temperatureTwo
            temperatureThree
            soilMoisture
            latitude
            longitude
            inputOne
            inputTwo 
            inputThree           
            minimumOne
            averageOne
            maximumOne
            minimumTwo
            averageTwo
            maximumTwo  
            minimumThree
            averageThree
            maximumThree  
            numberOfRows         
          }
     }
          `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    lastTemperaturesReadApi = data.data.lastTemperaturesRead

    return true
  })
}

let lastTemperaturesReadGridApi: DashboardTemperatureTypescript[]
const lastTemperaturesGridRequisicao = async (
  params: LastTemperatureReadTypescript,
) => {
  const query = `
        query {
          lastTemperaturesRead(find:{
            ${params?.limit !== undefined ? 'limit: ' + params?.limit : ''}
            ${params?.skip !== undefined ? 'skip: ' + params?.skip : ''}
            ${
              params?.device?.idEconomicGroup !== undefined
                ? 'idEconomicGroup: "' + params?.device?.idEconomicGroup + '"'
                : ''
            }
            ${
              params?.device?.branchNumber !== undefined
                ? 'branchNumber: ' + params?.device?.branchNumber
                : ''
            }
            ${
              params?.device?.serialNumber !== undefined
                ? 'serialNumber: "' + params?.device?.serialNumber + '"'
                : ''
            }
          }) {
           device {
             deviceType
             description
             serialNumber
             branchNumber
             latitude
             latitude
             latitude
             longitude
             inputNameOne
             inputNameTwo
             inputNameThree
             temperatureNameOne
             temperatureNameTwo
             temperatureNameThree
             economicGroupDetail{
              _id
              groupName
              groupNumber
            }
            tActiveOne
           tActiveTwo
           tActiveThree
           }
           deviceId
           timeStamp
           gpsTimeStamp
           temperatureOne
           temperatureTwo
           temperatureThree
           soilMoisture
           latitude
           longitude
           inputOne
           inputTwo 
           inputThree           
           minimumOne
           averageOne
           maximumOne
           minimumTwo
           averageTwo
           maximumTwo  
           minimumThree
           averageThree
           maximumThree
           numberOfRows    
         }
     }
          `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    lastTemperaturesReadGridApi = data.data.lastTemperaturesRead

    return true
  })
}

const addTemperatureRequisicao = async (action: any) => {
  const temperature: TemperatureTypescript = action.payload
  await api({
    method: 'post',
    data: {
      query: `
        mutation addTemperature {
          createTemperatures(
            deviceId: "${temperature.deviceId}"
            inputOne: "${temperature.inputOne}"
            inputTwo: "${temperature.inputTwo}"
            inputThree: "${temperature.inputTwo}"
            temperatureOne: "${temperature.temperatureOne}"
            temperatureTwo: "${temperature.temperatureTwo}"
            temperatureThree: "${temperature.temperatureThree}"
            timeStamp: "${temperature.timeStamp}"
            )
            {
            _id
            deviceId
            timeStamp
            temperatureOne
            temperatureTwo
            temperatureThree
            inputOne
            inputTwo
            inputThree
            
          }
        }
      `,
    },
  }).then(() => {
    // addDevicesApi = data.data.createDevices
    return true
  })
}

function* oneTemperature() {
  try {
    yield call(oneTemperatureRequisicao)
    yield put(TemperaturesActions.oneTemperatureSuccess())
  } catch {
    yield put(TemperaturesActions.oneTemperatureFailure())
  }
}
function* oneDashboardTemperature(action) {
  try {
    yield call(oneDashboardTemperatureRequisicao, action.payload)
    yield put(
      TemperaturesActions.oneDashboardTemperatureSuccess(
        oneDashboardTemperatureApi,
      ),
    )
  } catch {
    yield put(TemperaturesActions.oneDashboardTemperatureFailure())
  }
}

function* allTemperatures() {
  try {
    yield call(allTemperaturesRequisicao)
    yield put(TemperaturesActions.allTemperaturesSuccess(allTemperaturesApi))
  } catch {
    yield put(TemperaturesActions.allTemperaturesFailure())
  }
}
function* lastTemperaturesRead(action) {
  try {
    yield call(lastTemperaturesRequisicao, action.payload)
    yield put(
      TemperaturesActions.lastTemperaturesReadSuccess(lastTemperaturesReadApi),
    )
  } catch {
    yield put(TemperaturesActions.lastTemperaturesReadFailure())
  }
}
function* lastTemperaturesReadGrid(action) {
  try {
    yield call(lastTemperaturesGridRequisicao, action.payload)
    yield put(
      TemperaturesActions.lastTemperaturesReadGridSuccess(
        lastTemperaturesReadGridApi,
      ),
    )
  } catch {
    yield put(TemperaturesActions.lastTemperaturesReadGridFailure())
  }
}

export default all([
  takeLatest(types.ONE_TEMPERATURE_REQUEST, oneTemperature),
  takeLatest(types.ALL_TEMPERATURES_REQUEST, allTemperatures),
  takeLatest(types.ONE_DASHBOARD_TEMPERATURE_REQUEST, oneDashboardTemperature),
  takeLatest(types.LAST_TEMPERATURES_READ_REQUEST, lastTemperaturesRead),
  takeLatest(
    types.LAST_TEMPERATURES_READ_GRID_REQUEST,
    lastTemperaturesReadGrid,
  ),
  takeLatest(types.ADD_TEMPERATURE_REQUEST, addTemperatureRequisicao),
])
