import { UserState, UsersTypescript } from '../../../typescript'
import * as types from '../../types'

const initialState: UserState = {
  loading: false,
  loadingFiltered: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  loadingUpdGroup: false,
  addUser: {
    _id: '',
    email: '',
    userName: '',
    password: '',
    profiles: '',
    associatedBranches: '',
    token: '',
    admin: false,
    associatedResellers: '',
    result: false,
    message: '',
    err: '',
  },
  updUser: {
    _id: '',
    email: '',
    userName: '',
    password: '',
    profiles: '',
    associatedBranches: '',
    token: '',
    admin: false,
    associatedResellers: '',
    result: false,
    message: '',
    err: '',
  },
  updUserGroup: {
    _id: '',
    email: '',
    userName: '',
    password: '',
    profiles: '',
    associatedBranches: '',
    token: '',
    admin: false,
    associatedResellers: '',
    result: false,
    message: '',
    err: '',
  },
  user: {
    _id: '',
    email: '',
    userName: '',
    password: '',
    profiles: '',
    associatedBranches: '',
    token: '',
    admin: false,
    associatedResellers: '',
    result: false,
    message: '',
    err: '',
  },
  param: {
    _id: '',
    idGroup: '',
    email: '',
    userName: '',
  },
  users: [],
  usersFiltered: [],
  error: null,
}

const userReducer = (
  state = initialState,
  action: {
    type: string
    payload: UsersTypescript[] | UsersTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        user: action.payload,
        addUser: { ...action.payload, result: false, message: '' },
        updUser: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
        loadingUpdGroup: true,
      }
    case types.ONE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loadingOne: false,
        loadingUpdGroup: false,
      }
    case types.ONE_USER_FAILURE:
      return state

    case types.ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        param: action.payload,
        addUser: { ...action.payload, result: false, message: '' },
        updUser: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }
    case types.ALL_USERS_SUCCESS:
      return { ...state, users: action.payload, loading: false }
    case types.ALL_USERS_FAILURE:
      return { ...state, loading: false }

    case types.ADD_USER_REQUEST:
      return { ...state, addUser: action.payload, loadingAdd: true }

    case types.ADD_USER_SUCCESS:
      return { ...state, addUser: action.payload, loadingAdd: false }

    case types.ADD_USER_FAILURE:
      return { ...state, loadingAdd: false }

    case types.UPD_USER_REQUEST:
      return {
        ...state,
        updUser: action.payload,
        loadingUpd: true,
      }

    case types.UPD_USER_SUCCESS:
      return { ...state, updUser: action.payload, loadingUpd: false }

    case types.UPD_USER_FAILURE:
      return state

    case types.UPD_USER_GROUP_REQUEST:
      return { ...state, updUserGroup: action.payload, loadingUpdGroup: true }

    case types.UPD_USER_GROUP_SUCCESS:
      return { ...state, updUserGroup: action.payload, loadingUpdGroup: false }

    case types.UPD_USER_GROUP_FAILURE:
      return state

    case types.DEL_USER_REQUEST:
      return state

    case types.DEL_USER_SUCCESS:
      return { ...state }

    case types.DEL_USER_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default userReducer
