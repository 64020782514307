import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { ResellersTypescript } from '../../../typescript'
import * as ResellersActions from '../../actions/resellers'
import * as types from '../../types'

let allResellersApi: ResellersTypescript[]
const allResellersRequisicao = async (reseller: ResellersTypescript) => {
  // let json = JSON.stringify(reseller);
  // json = json.replace(/"([^"]+)":/g, '$1:');
  const query = `
  query {
    allResellers (find: {
      ${
        reseller.identificationDocument
          ? 'identificationDocument: "' + reseller.identificationDocument + '"'
          : ''
      }
      ${reseller.resaleName ? 'resaleName: "' + reseller.resaleName + '"' : ''}
      ${reseller.resaleNumber ? 'resaleNumber: ' + reseller.resaleNumber : ''}
    }) {
        _id
        resaleNumber
        resaleName
        identificationDocument
        address
        number
        district
        city
        complement
        zipCode
        state
        country
        phoneNumber
        email
        responsible
        observations
        associatedGroups
        result
        message
        err
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    if (data.data.allResellers === null) {
      data.data.allResellers = []
    }

    allResellersApi = data.data.allResellers
    return true
  })
}

let allowedResellersApi: ResellersTypescript[]
const allowedResellersRequisicao = async () => {
  const query = `
  query{
    allowedResellers
    {
      _id   
      resaleNumber
      resaleName
      address
      number
      associatedGroups
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    if (data.data.allowedResellers === null) {
      data.data.allowedResellers = []
    }

    allowedResellersApi = data.data.allowedResellers
    return true
  })
}

let addResellerApi
const addResellerRequisicao = async (addReseller: ResellersTypescript) => {
  await api({
    method: 'post',
    data: {
      query: `
        mutation {
          createResellers(input:{
            resaleName: "${addReseller.resaleName}",
            identificationDocument: "${addReseller.identificationDocument}",
            address: "${addReseller.address}",
            number: ${addReseller.number},
            district: "${addReseller.district}",
            city: "${addReseller.city}",
            complement: "${addReseller.complement}",
            zipCode: "${addReseller.zipCode}",
            state: "${addReseller.state}",
            country: "${addReseller.country}",
            phoneNumber: "${addReseller.phoneNumber}",
            email: "${addReseller.email}",
            responsible: "${addReseller.responsible}",
            observations: "${addReseller.observations}",
            associatedGroups: "${addReseller.associatedGroups}",
            }) {           
            result
            message
            err
          }
        }
      `,
    },
  })
    .then(({ data }) => {
      addResellerApi = data.data.createResellers
      return true
    })
    .catch((e) => e)
}

let updateResellerApi
const updateResellerRequisicao = async (updReseller: ResellersTypescript) => {
  await api({
    method: 'post',
    data: {
      query: `
        mutation {
          updateResellers(update:{
            _id: "${updReseller._id}",            
            resaleName: "${updReseller.resaleName}",
            identificationDocument: "${updReseller.identificationDocument}",
            address: "${updReseller.address}",
            number: ${updReseller.number},
            district: "${updReseller.district}",
            city: "${updReseller.city}",
            complement: "${updReseller.complement}",
            zipCode: "${updReseller.zipCode}",
            state: "${updReseller.state}",
            country: "${updReseller.country}",
            phoneNumber: "${updReseller.phoneNumber}",
            email: "${updReseller.email}",
            responsible: "${updReseller.responsible}",
            observations: "${updReseller.observations}",
            associatedGroups: "${updReseller.associatedGroups}",
            }) {            
            result
            message
            err
          }
        }
      `,
    },
  })
    .then(({ data }) => {
      updateResellerApi = data.data.updateResellers

      return true
    })
    .catch((e) => e)
}

function* allResellers(action) {
  try {
    yield call(allResellersRequisicao, action.payload)
    yield put(ResellersActions.allResellersSuccess(allResellersApi))
  } catch {
    yield put(ResellersActions.allResellersFailure())
  }
}

function* allowedResellers() {
  try {
    yield call(allowedResellersRequisicao)
    yield put(ResellersActions.allowedResellersSuccess(allowedResellersApi))
  } catch {
    yield put(ResellersActions.allowedResellersFailure())
  }
}

function* addReseller(action) {
  try {
    yield call(addResellerRequisicao, action.payload)
    yield put(ResellersActions.addResellerSuccess(addResellerApi))
  } catch {
    yield put(ResellersActions.addResellerFailure())
  }
}

function* updReseller(action) {
  try {
    yield call(updateResellerRequisicao, action.payload)
    yield put(ResellersActions.updResellerSuccess(updateResellerApi))
  } catch {
    yield put(ResellersActions.updResellerFailure(updateResellerApi))
  }
}

export default all([
  takeLatest(types.ALL_RESELLERS_REQUEST, allResellers),
  takeLatest(types.ALLOWED_RESELLERS_REQUEST, allowedResellers),
  takeLatest(types.ADD_RESELLER_REQUEST, addReseller),
  takeLatest(types.UPD_RESELLER_REQUEST, updReseller),
])
