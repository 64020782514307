import jwtDecode from 'jwt-decode'
import { TypeChecker } from 'typescript'
import { ProfilesTypescript } from '../../typescript'

export const isEmpty = (value: any): boolean => {
  if (value && value instanceof String) value = value.replace(/\s/g, '')
  switch (value) {
    case '':
    case 0:
    case '0':
    case null:
    case false:
    case undefined:
    case typeof value == 'undefined':
      return true
    default:
      return false
  }
}

export const decodeToken = (): any => {
  const token = localStorage.getItem('global-iot/token')
  if (token) {
    const decoded = jwtDecode<any>(token) // Returns with the JwtPayload type
    const { idUser, version, exp } = decoded
    return { idUser, version, exp }
  }

  return null
}

export function testBit(byte, bit) {
  if (!byte) return false
  return (byte >> bit) % 2 !== 0
}

//A função testBit funciona da seguinte maneira?
// deve ser passada como byte o valor da permissão dos menus que podem ser:
// 1 = Visualizar
// 2 = Edição
// 4 = Criação

// O Valor passado será sempre a somatória das tres permissões.
// No parâmetro bit será passado o valor do bit que se esta testando, onde:
// 0 = Visualizar
// 1 = Editar
// 2 = Criar
// Desta maneira em cima da função testBit que desloca o valor de byte pelo valor de bit
// e dividindo o resultado pelo mod 2, caso seja zero retorna false para a permissão,
// caso seja > 0 retorna true e a permissão existe.

export function between(number, from, to) {
  return (number >> from) & ((1 << (to - from)) - 1)
}

export function from(number, from) {
  return number >> from
}

export function setBit(byte, bit) {
  return byte | (1 << bit)
}

export function clearBit(byte, bit) {
  return byte & ~(1 << bit)
}

export function parseBool(b) {
  return !/^(false|0)$/i.test(b) && !!b
}

// Handle Permissions Profiles
export const handleChangePermissions = (
  group: string,
  bit: number,
  e: React.FormEvent<TypeChecker>,
  profile: ProfilesTypescript,
  setProfile: React.Dispatch<React.SetStateAction<ProfilesTypescript>>,
) => {
  let word: number = profile[group]
  word = (e.target as HTMLInputElement).checked
    ? setBit(word, bit)
    : clearBit(word, bit)
  if (!(e.target as HTMLInputElement).checked && bit === 0) {
    word = 0
  } else if (testBit(word, 1) || testBit(word, 2)) {
    word = setBit(word, 0)
  }
  setProfile({ ...profile, [group]: word })
}

export const testEmail = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^[\w\-\.]{2,}[@][\w\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[a-z])?/,
)
