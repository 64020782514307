export const DASHBOARD_CARDS_REQUEST = 'DASHBOARD_CARDS_REQUEST'
export const DASHBOARD_CARDS_SUCCESS = 'DASHBOARD_CARDS_SUCCESS'
export const DASHBOARD_CARDS_FAILURE = 'DASHBOARD_CARDS_FAILURE'
export const DASHBOARD_CARDS_GROUPS_REQUEST = 'DASHBOARD_CARDS_GROUPS_REQUEST'
export const DASHBOARD_CARDS_GROUPS_SUCCESS = 'DASHBOARD_CARDS_GROUPS_SUCCESS'
export const DASHBOARD_CARDS_GROUPS_FAILURE = 'DASHBOARD_CARDS_GROUPS_FAILURE'
export const DASHBOARD_CARDS_DEVICES_REQUEST = 'DASHBOARD_CARDS_DEVICES_REQUEST'
export const DASHBOARD_CARDS_DEVICES_SUCCESS = 'DASHBOARD_CARDS_DEVICES_SUCCESS'
export const DASHBOARD_CARDS_DEVICES_FAILURE = 'DASHBOARD_CARDS_DEVICES_FAILURE'
export const DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST =
  'DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST'
export const DASHBOARD_CARDS_ONLINE_OFFLINE_SUCCESS =
  'DASHBOARD_CARDS_ONLINE_OFFLINE_SUCCESS'
export const DASHBOARD_CARDS_ONLINE_OFFLINE_FAILURE =
  'DASHBOARD_CARDS_ONLINE_OFFLINE_FAILURE'
export const DASHBOARD_CARDS_ALERTS_FAIL_REQUEST =
  'DASHBOARD_CARDS_ALERTS_FAIL_REQUEST'
export const DASHBOARD_CARDS_ALERTS_FAIL_SUCCESS =
  'DASHBOARD_CARDS_ALERTS_FAIL_SUCCESS'
export const DASHBOARD_CARDS_ALERTS_FAIL_FAILURE =
  'DASHBOARD_CARDS_ALERTS_FAIL_FAILURE'
