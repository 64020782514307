import { MenusTypescript } from '../../../typescript'
import * as types from '../../types'

export function allMenusRequest(menu: MenusTypescript): {
  type: string
  payload: MenusTypescript
} {
  return {
    type: types.ALL_MENUS_REQUEST,
    payload: menu,
  }
}
export function allMenusSuccess(data: MenusTypescript[]): {
  type: string
  payload: MenusTypescript[]
} {
  return {
    type: types.ALL_MENUS_SUCCESS,
    payload: data,
  }
}
export function allMenusFailure(): { type: string } {
  return {
    type: types.ALL_MENUS_FAILURE,
  }
}

export function addMenuRequest(menu: MenusTypescript): {
  type: string
  payload: MenusTypescript
} {
  return {
    type: types.ADD_MENU_REQUEST,
    payload: menu,
  }
}
export function addMenuSuccess(data: MenusTypescript): {
  type: string
  payload: MenusTypescript
} {
  return {
    type: types.ADD_MENU_SUCCESS,
    payload: data,
  }
}
export function addMenuFailure(): { type: string } {
  return {
    type: types.ADD_MENU_FAILURE,
  }
}

export function updMenuRequest(menu: MenusTypescript): {
  type: string
  payload: MenusTypescript
} {
  return {
    type: types.UPD_MENU_REQUEST,
    payload: menu,
  }
}
export function updMenuSuccess(data: MenusTypescript[]): {
  type: string
  payload: MenusTypescript[]
} {
  return {
    type: types.UPD_MENU_SUCCESS,
    payload: data,
  }
}
export function updMenuFailure(data: MenusTypescript[]): {
  type: string
  payload: MenusTypescript[]
} {
  return {
    type: types.UPD_MENU_FAILURE,
    payload: data,
  }
}
