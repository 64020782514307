/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { CommandsTypescript } from '../../../typescript'
import { ParamsToFilter } from '../../../views/commands/dataTable/fields'
import {
  addCommandFailure,
  addCommandSuccess,
  allCommandsFailure,
  allCommandsSuccess,
  updCommandFailure,
  updCommandSuccess,
} from '../..'
import * as types from '../../types'

let allCommandsApi: CommandsTypescript[]
//first: 100 offset: 0

// const allCommandsRequisicao = async () => {

const allCommandsRequisicao = async (commands: ParamsToFilter) => {
  const query = `
        query {
          allCommands(find: {
            ${commands.deviceId ? 'deviceId: "' + commands.deviceId + '"' : ''}
            ${
              commands.commandType && commands.commandType !== '0'
                ? 'commandType: "' + commands.commandType + '"'
                : ''
            }
            ${
              commands.registrationDate
                ? 'registrationDate: "' + commands.registrationDate + '"'
                : ''
            }
            ${commands.status ? 'status: ' + commands.status : ''}
          }) {
            _id
            deviceId
            registrationUser
            registrationDate
            executedDate
            canceledDate
            commandType
            params
            executions
            status
            result
            message
            err
          }
        }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allCommandsApi = data.data.allCommands
    return true
  })
}

let addCommandApi: CommandsTypescript
const addCommandRequisicao = async (addCommand: CommandsTypescript) => {
  const query = `
  mutation {
    createCommands(input:{
      ${addCommand.deviceId ? 'deviceId: "' + addCommand.deviceId + '"' : ''}
      ${
        addCommand.commandType && addCommand.commandType !== '0'
          ? 'commandType: "' + addCommand.commandType + '"'
          : ''
      }
      ${
        addCommand.actuatorsCommand
          ? 'actuatorsCommand: "' + addCommand.actuatorsCommand + '"'
          : ''
      }
    })
    {
      result
      message
      err 
    }

}
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    addCommandApi = data.data.createCommands
    return true
  })
}

let updCommandApi: CommandsTypescript
const updCommandRequisicao = async (updCommand: CommandsTypescript) => {
  const query = `
  mutation {
    cancelCommand (update:{
      _id: "${updCommand._id}"
    }){
        result
        message
        err
    }
  }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updCommandApi = data.data.cancelCommand
    return true
  })
}

function* allCommands(action) {
  try {
    yield call(allCommandsRequisicao, action.payload)
    yield put(allCommandsSuccess(allCommandsApi))
  } catch {
    yield put(allCommandsFailure())
  }
}
function* addCommand(action) {
  try {
    yield call(addCommandRequisicao, action.payload)
    yield put(addCommandSuccess(addCommandApi))
  } catch {
    yield put(addCommandFailure())
  }
}
function* updCommand(action) {
  try {
    yield call(updCommandRequisicao, action.payload)
    yield put(updCommandSuccess(updCommandApi))
  } catch {
    yield put(updCommandFailure())
  }
}
export default all([
  takeLatest(types.ALL_COMMANDS_REQUEST, allCommands),
  takeLatest(types.ADD_COMMAND_REQUEST, addCommand),
  takeLatest(types.UPD_COMMAND_REQUEST, updCommand),
])
