/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EventTypesTypescript } from '../../../typescript'
import * as types from '../../types'

export interface EventTypeState {
  loading: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  eventType: EventTypesTypescript
  addEventType: EventTypesTypescript
  updEventType: EventTypesTypescript
  eventTypes: EventTypesTypescript[]
  error: string | null
}

const initialState: EventTypeState = {
  loading: true,
  loadingAdd: true,
  loadingUpd: true,
  addEventType: {
    _id: '',
    idEconomicGroup: '',
    branchNumber: 0,
    eventType: '',
    deviceArea: '',
    ruleOne: 0,
    conditionOne: '',
    valueOne: 0,
    ruleTwo: 0,
    conditionTwo: '',
    valueTwo: 0,
    ruleThree: 0,
    conditionThree: '',
    valueThree: 0,
    ruleFour: 0,
    conditionFour: '',
    valueFour: 0,
    result: true,
    message: '',
    err: '',
  },
  updEventType: {
    _id: '',
    idEconomicGroup: '',
    branchNumber: 0,
    eventType: '',
    deviceArea: '',
    ruleOne: 0,
    conditionOne: '',
    valueOne: 0,
    ruleTwo: 0,
    conditionTwo: '',
    valueTwo: 0,
    ruleThree: 0,
    conditionThree: '',
    valueThree: 0,
    ruleFour: 0,
    conditionFour: '',
    valueFour: 0,
    result: true,
    message: '',
    err: '',
  },
  eventType: {
    _id: '',
    idEconomicGroup: '',
    branchNumber: 0,
    eventType: '',
    deviceArea: '',
    ruleOne: 0,
    conditionOne: '',
    valueOne: 0,
    ruleTwo: 0,
    conditionTwo: '',
    valueTwo: 0,
    ruleThree: 0,
    conditionThree: '',
    valueThree: 0,
    ruleFour: 0,
    conditionFour: '',
    valueFour: 0,
    result: true,
    message: '',
    err: '',
  },
  eventTypes: [],
  error: null,
}

const eventTypeReducer = (
  state = initialState,
  action: {
    type: string
    payload: EventTypesTypescript[] | EventTypesTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_EVENT_TYPES_REQUEST:
      return {
        ...state,
        params: action.payload,
        loading: true,
        addEventType: { ...action.payload, result: false, message: '' },
        updEventType: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_EVENT_TYPES_SUCCESS:
      return { ...state, eventTypes: action.payload, loading: false }

    case types.ALL_EVENT_TYPES_FAILURE:
      return state

    case types.ADD_EVENT_TYPE_REQUEST:
      return { ...state, addEventType: action.payload, loadingAdd: true }

    case types.ADD_EVENT_TYPE_SUCCESS:
      return { ...state, addEventType: action.payload, loadingAdd: false }

    case types.ADD_EVENT_TYPE_FAILURE:
      return { ...state, loadingAdd: false }

    case types.UPD_EVENT_TYPE_REQUEST:
      return { ...state, updEventType: action.payload, loadingUpd: true }

    case types.UPD_EVENT_TYPE_SUCCESS:
      return { ...state, updEventType: action.payload, loadingUpd: false }

    case types.UPD_EVENT_TYPE_FAILURE:
      return { ...state, updEventType: action.payload }
    default:
      return { ...state }
  }
}
export default eventTypeReducer
