import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { BranchesTypescript } from '../../../typescript'
import {
  addBranchFailure,
  addBranchSuccess,
  oneBrancheRequest,
  oneBrancheSuccess,
  oneBrancheFailure,
  allBranchesByGroupSuccess,
  allBranchesByUserSuccess,
  allBranchesByUserFailure,
  allBranchesFailure,
  allBranchesSuccess,
  updBranchFailure,
  updBranchSuccess,
} from '../..'
import * as types from '../../types'

let oneBrancheApi: BranchesTypescript
const oneBrancheRequisicao = async (param: BranchesTypescript) => {
  const query = `
    query {
      oneBranch(_id: "${param._id}") { 
        _id
        branchNumber
        corporateName
        tradingName
        identificationDocument
        address
        number
        complement
        district
        city
        state
        country
        zipCode
        phoneNumber
        email
        responsible
        idEconomicGroup
        latitude
        longitude
        typeOfPerson
        icon
      }
    }
    `

  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      oneBrancheApi = data.data.oneBranch
      return true
    })
    .catch((e) => console.log('meu erro é: ', e))
}

let allBranchesApi: BranchesTypescript[]
const allBranchesRequisicao = async (branch: BranchesTypescript) => {
  const query = `
      query {
        allBranches(find: {
          ${
            branch.identificationDocument
              ? 'identificationDocument: "' +
                branch.identificationDocument +
                '"'
              : ''
          }
          ${
            branch.tradingName
              ? 'tradingName: "' + branch.tradingName + '"'
              : ''
          }
          ${
            branch.idEconomicGroup
              ? 'idEconomicGroup: "' + branch.idEconomicGroup + '"'
              : ''
          }
          ${branch.branchNumber ? 'branchNumber: ' + branch.branchNumber : ''}
          ${
            branch.corporateName
              ? 'corporateName: "' + branch.corporateName + '"'
              : ''
          }
        }) {
          _id
          branchNumber
          corporateName
          tradingName
          identificationDocument
          address
          number
          complement
          district
          city
          state
          country
          zipCode
          phoneNumber
          email
          responsible
          idEconomicGroup
          latitude
          longitude
          typeOfPerson
          icon
        }
      }
        `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allBranchesApi = data.data.allBranches
    return true
  })
}

let allBranchesByUserApi: BranchesTypescript[]
const allBranchesByUserRequisicao = async (userGroup: string) => {
  const query = `
      query {
        allBranchesByUser(find: {
          ${userGroup ? 'economicGroupUser: "' + userGroup + '"' : ''}
          
        }) {
          _id
          branchNumber
          corporateName
          tradingName          
          idEconomicGroup          
        }
      }
        `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allBranchesByUserApi = data.data.allBranchesByUser
    return true
  })
}

let allBranchesByGroupApi: BranchesTypescript[]
const allBranchesByGroupRequisicao = async (branch: BranchesTypescript) => {
  const query = `
    query allBranchesByGroup{
      allBranchesByGroup(find:{ idEconomicGroup: ${`"${branch.idEconomicGroup}"`}})
      {
        _id
        branchNumber
        corporateName
        tradingName
        identificationDocument
        address
        number
        complement
        district
        city
        state
        country
        zipCode
        phoneNumber
        email
        responsible
        idEconomicGroup
        latitude
        longitude
        typeOfPerson
        icon
      }
    }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allBranchesByGroupApi = data.data.allBranchesByGroup
    return true
  })
}

let addBranchApi: BranchesTypescript
const addBranchRequisicao = async (addBranch: BranchesTypescript) => {
  await api({
    method: 'post',
    data: {
      query: `
        mutation {
          createBranches(input:{
            corporateName: "${addBranch.corporateName}",
            branchNumber: ${addBranch.branchNumber},
            tradingName: "${addBranch.tradingName}",
            identificationDocument: "${addBranch.identificationDocument}",
            address: "${addBranch.address}",
            number: ${addBranch.number},
            complement: "${addBranch.complement}",
            district: "${addBranch.district}",
            city: "${addBranch.city}",
            state: "${addBranch.state}",
            country: "${addBranch.country}",
            zipCode: "${addBranch.zipCode}",
            phoneNumber: "${addBranch.phoneNumber}",
            email: "${addBranch.email}",
            responsible: "${addBranch.responsible}",
            idEconomicGroup: "${addBranch.idEconomicGroup}",
            latitude: ${addBranch.latitude},
            longitude: ${addBranch.longitude},
            typeOfPerson: "${addBranch.typeOfPerson}",
            icon: "${addBranch.icon}",}) {
            result
            message
            err
          }
        }
      `,
    },
  }).then(({ data }) => {
    addBranchApi = data.data.createBranches
    return true
  })
}

let updateBranchApi
const updateBranchRequisicao = async (updBranch: BranchesTypescript) => {
  await api({
    method: 'post',
    data: {
      query: `
        mutation {
          updateBranches( update:{
          _id: "${updBranch._id}",
          corporateName: "${updBranch.corporateName}",
          tradingName: "${updBranch.tradingName}",
          identificationDocument: "${updBranch.identificationDocument}",
          address: "${updBranch.address}",
          number: ${updBranch.number},
          complement: "${updBranch.complement}",
          district: "${updBranch.district}",
          city: "${updBranch.city}",
          state: "${updBranch.state}",
          country: "${updBranch.country}",
          zipCode: "${updBranch.zipCode}",
          phoneNumber: "${updBranch.phoneNumber}",
          email: "${updBranch.email}",
          responsible: "${updBranch.responsible}",
          latitude: ${updBranch.latitude},
          longitude: ${updBranch.longitude},
          typeOfPerson: "${updBranch.typeOfPerson}",
          icon: "${updBranch.icon}"
          }) {
          result
          message
          err
          }
        }
      `,
    },
  })
    .then(({ data }) => {
      updateBranchApi = data.data.updateBranches
      return true
    })
    .catch((e) => e)
}
function* oneBranche(action) {
  try {
    yield call(oneBrancheRequisicao, action.payload)
    yield put(oneBrancheSuccess(oneBrancheApi))
  } catch {
    yield put(oneBrancheFailure())
  }
}
function* allBranches(action) {
  try {
    yield call(allBranchesRequisicao, action.payload)
    yield put(allBranchesSuccess(allBranchesApi))
  } catch {
    yield put(allBranchesFailure())
  }
}

function* allBranchesByGroup(action) {
  try {
    yield call(allBranchesByGroupRequisicao, action.payload)
    yield put(allBranchesByGroupSuccess(allBranchesByGroupApi))
  } catch {
    yield put(allBranchesFailure())
  }
}

function* allBranchesByUser(action) {
  try {
    yield call(allBranchesByUserRequisicao, action.payload)
    yield put(allBranchesByUserSuccess(allBranchesByUserApi))
  } catch {
    yield put(allBranchesByUserFailure())
  }
}

function* addBranch(action) {
  try {
    yield call(addBranchRequisicao, action.payload)
    yield put(addBranchSuccess(addBranchApi))
  } catch {
    yield put(addBranchFailure())
  }
}

function* updBranch(action) {
  try {
    yield call(updateBranchRequisicao, action.payload)
    yield put(updBranchSuccess(updateBranchApi))
  } catch {
    yield put(updBranchFailure(updateBranchApi))
  }
}

export default all([
  takeLatest(types.ONE_BRANCHE_REQUEST, oneBranche),
  takeLatest(types.ALL_BRANCHES_REQUEST, allBranches),
  takeLatest(types.ALL_BRANCHES_BY_GROUP_REQUEST, allBranchesByGroup),
  takeLatest(types.ALL_BRANCHES_BY_USER_REQUEST, allBranchesByUser),
  takeLatest(types.ADD_BRANCH_REQUEST, addBranch),
  takeLatest(types.UPD_BRANCH_REQUEST, updBranch),
])
