import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { PositionsTypescript } from '../../../typescript'
import * as PositionsActions from '../../actions/positions'
import * as types from '../../types'

const onePositionRequisicao = () =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, 2000)
  })

let allPositionsApi: []
const allPositionsRequisicao = async () => {
  await api({
    method: 'post',
    data: {
      query: `
        query {
          dashboardPositions(limit: ${60}) {
            device {
              _id
              description
              serialNumber
              latitude
              longitude
            }
            temperatures {
              _id
              deviceId
              timeStamp
              temperatureOne
              temperatureTwo
              temperatureThree
              inputOne
              inputTwo
            }
            records
            openings
            minimumOne
            averageOne
            maximumOne
            minimumTwo
            averageTwo
            maximumTwo
          }
        }
          `,
    },
  }).then(({ data }) => {
    allPositionsApi = data.data.dashboardPositions
    return true
  })
}
let lastPositionsApi: PositionsTypescript[]
const lastPositionsRequisicao = async () => {
  await api({
    method: 'post',
    data: {
      query: `
        query {
          lastSecurityPosition{
            _id
            deviceId
            timeStamp
            activeButton
            statusIO
            latitude
            longitude
          }
        }
          `,
    },
  }).then(({ data }) => {
    lastPositionsApi = data.data.dashboardositions
    return true
  })
}

// let addDevicesApi: DevicesTypescript
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addPositionRequisicao = async (action: any) => {
  const position: PositionsTypescript = action.payload
  await api({
    method: 'post',
    data: {
      query: `
        mutation addPosition {
          createPositions(
            deviceId: "${position.deviceId}"
            activeButton: "${position.activeButton}"
            latitude: "${position.latitude}"
            longitude: "${position.longitude}"
            statusIO: "${position.statusIO}"
            timeStamp: "${position.timeStamp}"
            )
            {
            _id
            deviceId
            activeButton
            latitude
            longitude
            statusIO
            timeStamp
          }
        }
      `,
    },
  }).then(() => {
    // addDevicesApi = data.data.createDevice

    return true
  })
}

function* onePosition() {
  try {
    yield call(onePositionRequisicao)
    yield put(PositionsActions.onePositionSuccess())
  } catch {
    yield put(PositionsActions.onePositionFailure())
  }
}
function* allPositions() {
  try {
    yield call(allPositionsRequisicao)
    yield put(PositionsActions.allPositionsSuccess(allPositionsApi))
  } catch {
    yield put(PositionsActions.allPositionsFailure())
  }
}
function* lastPositions() {
  try {
    yield call(lastPositionsRequisicao)
    yield put(PositionsActions.allPositionsSuccess(lastPositionsApi))
  } catch {
    yield put(PositionsActions.allPositionsFailure())
  }
}
export default all([
  takeLatest(types.ONE_POSITION_REQUEST, onePosition),
  takeLatest(types.ALL_POSITIONS_REQUEST, allPositions),
  takeLatest(types.ALL_LAST_POSITIONS_REQUEST, lastPositions),
  takeLatest(types.ADD_POSITION_REQUEST, addPositionRequisicao),
])
