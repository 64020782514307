import { CommandsTypescript } from '../../../typescript'
import { ParamsToFilter } from '../../../views/commands/dataTable/fields'
import * as types from '../../types'

export function allCommandsRequest(params): {
  type: string
  payload: ParamsToFilter
} {
  return {
    type: types.ALL_COMMANDS_REQUEST,
    payload: params,
  }
}
export function allCommandsSuccess(commands: CommandsTypescript[]): {
  type: string
  payload: CommandsTypescript[]
} {
  return {
    type: types.ALL_COMMANDS_SUCCESS,
    payload: commands,
  }
}
export function allCommandsFailure(): { type: string } {
  return {
    type: types.ALL_COMMANDS_FAILURE,
  }
}

export function addCommandRequest(command: CommandsTypescript): {
  type: string
  payload: CommandsTypescript
} {
  return {
    type: types.ADD_COMMAND_REQUEST,
    payload: command,
  }
}
export function addCommandSuccess(data: CommandsTypescript): {
  type: string
  payload: CommandsTypescript
} {
  return {
    type: types.ADD_COMMAND_SUCCESS,
    payload: data,
  }
}
export function addCommandFailure(): { type: string } {
  return {
    type: types.ADD_COMMAND_FAILURE,
  }
}

export function updCommandRequest(command: CommandsTypescript): {
  type: string
  payload: CommandsTypescript
} {
  return {
    type: types.UPD_COMMAND_REQUEST,
    payload: command,
  }
}
export function updCommandSuccess(data: CommandsTypescript): {
  type: string
  payload: CommandsTypescript
} {
  return {
    type: types.UPD_COMMAND_SUCCESS,
    payload: data,
  }
}
export function updCommandFailure(): { type: string } {
  return {
    type: types.UPD_COMMAND_FAILURE,
  }
}
