export const ONE_NEW_PROFILE_REQUEST = 'ONE_NEW_PROFILE_REQUEST'
export const ONE_NEW_PROFILE_SUCCESS = 'ONE_NEW_PROFILE_SUCCESS'
export const ONE_NEW_PROFILE_FAILURE = 'ONE_NEW_PROFILE_FAILURE'
export const ALL_NEW_PROFILES_REQUEST = 'ALL_NEW_PROFILES_REQUEST'
export const ALL_NEW_PROFILES_SUCCESS = 'ALL_NEW_PROFILES_SUCCESS'
export const ALL_NEW_PROFILES_FAILURE = 'ALL_NEW_PROFILES_FAILURE'
export const ALL_NEW_PROFILES_TREE_REQUEST = 'ALL_NEW_PROFILES_TREE_REQUEST'
export const ALL_NEW_PROFILES_TREE_SUCCESS = 'ALL_NEW_PROFILES_TREE_SUCCESS'
export const ALL_NEW_PROFILES_TREE_FAILURE = 'ALL_NEW_PROFILES_TREE_FAILURE'
export const ADD_NEW_PROFILE_REQUEST = 'ADD_NEW_PROFILE_REQUEST'
export const ADD_NEW_PROFILE_SUCCESS = 'ADD_NEW_PROFILE_SUCCESS'
export const ADD_NEW_PROFILE_FAILURE = 'ADD_NEW_PROFILE_FAILURE'
export const DEL_NEW_PROFILE_REQUEST = 'DEL_NEW_PROFILE_REQUEST'
export const DEL_NEW_PROFILE_SUCCESS = 'DEL_NEW_PROFILE_SUCCESS'
export const DEL_NEW_PROFILE_FAILURE = 'DEL_NEW_PROFILE_FAILURE'
export const UPD_NEW_PROFILE_REQUEST = 'UPD_NEW_PROFILE_REQUEST'
export const UPD_NEW_PROFILE_SUCCESS = 'UPD_NEW_PROFILE_SUCCESS'
export const UPD_NEW_PROFILE_FAILURE = 'UPD_NEW_PROFILE_FAILURE'
