export const LAST_WEATHERS_READ_REQUEST = 'LAST_WEATHERS_READ_REQUEST'
export const LAST_WEATHERS_READ_SUCCESS = 'LAST_WEATHERS_READ_SUCCESS'
export const LAST_WEATHERS_READ_FAILURE = 'LAST_WEATHERS_READ_FAILURE'
export const LAST_WEATHERS_READ_GRID_REQUEST = 'LAST_WEATHERS_READ_GRID_REQUEST'
export const LAST_WEATHERS_READ_GRID_SUCCESS = 'LAST_WEATHERS_READ_GRID_SUCCESS'
export const LAST_WEATHERS_READ_GRID_FAILURE = 'LAST_WEATHERS_READ_GRID_FAILURE'
export const LAST_WEATHERS_READ_GRID_RESET_REQUEST =
  'LAST_WEATHERS_READ_GRID_RESET_REQUEST'
export const LAST_WEATHERS_READ_GRID_RESET_SUCCESS =
  'LAST_WEATHERS_READ_GRID_RESET_SUCCESS'
export const LAST_WEATHERS_READ_GRID_RESET_FAILURE =
  'LAST_WEATHERS_READ_GRID_RESET_FAILURE'
