import { createSelector } from 'reselect'
import { UsersTypescript } from '../../../typescript'

// Getters
const getUsers = (state: { users: { users: UsersTypescript[] } }) =>
  state.users.users
const addUser = (state: { users: { addUser: UsersTypescript } }) =>
  state.users.addUser
const updUser = (state: { users: { updUser: UsersTypescript } }) =>
  state.users.updUser
const updUserGroup = (state: { users: { updUserGroup: UsersTypescript } }) =>
  state.users.updUserGroup
const getUser = (state: { users: { user: UsersTypescript } }) =>
  state.users.user

// Loadings
const getLoading = (state: { users: { loading: boolean } }) => {
  console.log('AQUI', {
    ...state.users,
  })
  return state.users.loading
}
const addLoading = (state: { users: { loadingAdd: boolean } }) =>
  state.users.loadingAdd
const updLoading = (state: { users: { loadingUpd: boolean } }) =>
  state.users.loadingUpd
const updGroupLoading = (state: { users: { loadingUpdGroup: boolean } }) =>
  state.users.loadingUpdGroup
const getOneLoading = (state: { users: { loadingOne: boolean } }) =>
  state.users.loadingOne

// exports
export const getUsersSelector = createSelector(
  getUsers,
  getLoading,
  (users: UsersTypescript[]) => users,
)
export const getUserSelector = createSelector(
  getUser,
  getOneLoading,
  (user: UsersTypescript) => user,
)
export const addUserSelector = createSelector(
  addUser,
  addLoading,
  (addUser: UsersTypescript) => addUser,
)
export const updUserSelector = createSelector(
  updUser,
  updLoading,
  (updUser: UsersTypescript) => updUser,
)
export const updUserGroupSelector = createSelector(
  updUserGroup,
  updGroupLoading,
  (updUserGroup: UsersTypescript) => updUserGroup,
)

// Exports Loadings
export const getLoadingUsersSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingUserSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddUserSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdUserSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
export const getLoadingUpdUserGroupSelector = createSelector(
  updGroupLoading,
  (loadingUpdGroup: boolean) => loadingUpdGroup,
)
