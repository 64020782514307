import { createSelector } from 'reselect'
import { EventTypesTypescript } from '../../../typescript'

// Getters
const getEventTypes = (state: {
  eventTypes: { eventTypes: EventTypesTypescript[] }
}) => state.eventTypes.eventTypes
const addEventType = (state: {
  eventTypes: { addEventType: EventTypesTypescript }
}) => state.eventTypes.addEventType
const updEventType = (state: {
  eventTypes: { updEventType: EventTypesTypescript }
}) => state.eventTypes.updEventType
const getEventType = (state: {
  eventTypes: { eventType: EventTypesTypescript }
}) => state.eventTypes.eventType

// Loadings
const getLoading = (state: { eventTypes: { loading: boolean } }) =>
  state.eventTypes.loading
const addLoading = (state: { eventTypes: { loadingAdd: boolean } }) =>
  state.eventTypes.loadingAdd
const updLoading = (state: { eventTypes: { loadingUpd: boolean } }) =>
  state.eventTypes.loadingUpd
const getOneLoading = (state: { eventTypes: { loadingOne: boolean } }) =>
  state.eventTypes.loadingOne

// exports
export const getEventTypesSelector = createSelector(
  getEventTypes,
  getLoading,
  (eventTypes: EventTypesTypescript[]) => eventTypes,
)
export const getEventTypeSelector = createSelector(
  getEventType,
  getOneLoading,
  (eventType: EventTypesTypescript) => eventType,
)
export const addEventTypeSelector = createSelector(
  addEventType,
  addLoading,
  (addEventType: EventTypesTypescript) => addEventType,
)
export const updEventTypeSelector = createSelector(
  updEventType,
  updLoading,
  (updEventType: EventTypesTypescript) => updEventType,
)

// Exports Loadings
export const getLoadingEventTypesSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingEventTypeSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddEventTypeSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdEventTypeSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
