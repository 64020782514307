import { DepartmentState, DepartmentsTypescript } from '../../../typescript'
import * as types from '../../types'

const initialState: DepartmentState = {
  loading: false,
  loadingFiltered: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  addDepartment: {
    _id: '',
    idBranch: '',
    idEconomicGroup: '',
    departmentNumber: undefined,
    description: '',
    xCoordinate: undefined,
    yCoordinate: undefined,
    result: false,
    message: '',
    err: '',
  },
  updDepartment: {
    _id: '',
    idBranch: '',
    idEconomicGroup: '',
    departmentNumber: undefined,
    description: '',
    xCoordinate: undefined,
    yCoordinate: undefined,
    result: false,
    message: '',
    err: '',
  },
  department: {
    _id: '',
    idBranch: '',
    idEconomicGroup: '',
    departmentNumber: undefined,
    description: '',
    xCoordinate: undefined,
    yCoordinate: undefined,
    result: false,
    message: '',
    err: '',
  },
  departments: [],
  error: null,
}

const departmentReducer = (
  state = initialState,
  action: {
    type: string
    payload: DepartmentsTypescript[] | DepartmentsTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        department: action.payload,
        addDepartment: { ...action.payload, result: false, message: '' },
        updDepartment: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
        loadingUpdGroup: true,
      }
    case types.ONE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        department: action.payload,
        loadingOne: false,
        loadingUpdGroup: false,
      }
    case types.ONE_DEPARTMENT_FAILURE:
      return state

    case types.ALL_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        addDepartment: { ...action.payload, result: false, message: '' },
        updDepartment: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }
    case types.ALL_DEPARTMENTS_SUCCESS:
      return { ...state, departments: action.payload, loading: false }
    case types.ALL_DEPARTMENTS_FAILURE:
      return { ...state, loading: false }

    case types.ADD_DEPARTMENT_REQUEST:
      return { ...state, addDepartment: action.payload, loadingAdd: true }

    case types.ADD_DEPARTMENT_SUCCESS:
      return { ...state, addDepartment: action.payload, loadingAdd: false }

    case types.ADD_DEPARTMENT_FAILURE:
      return { ...state, loadingAdd: false }

    case types.UPD_DEPARTMENT_REQUEST:
      return {
        ...state,
        updDepartment: action.payload,
        loadingUpd: true,
      }

    case types.UPD_DEPARTMENT_SUCCESS:
      return { ...state, updDepartment: action.payload, loadingUpd: false }

    case types.UPD_DEPARTMENT_FAILURE:
      return state

    case types.DEL_DEPARTMENT_REQUEST:
      return state

    case types.DEL_DEPARTMENT_SUCCESS:
      return { ...state }

    case types.DEL_DEPARTMENT_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default departmentReducer
