/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BranchesTypescript } from '../../../typescript'
import * as types from '../../types'

export interface BranchState {
  loading: boolean
  loadingByGroup: boolean
  loadingFiltered: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  branch: BranchesTypescript
  addBranch: BranchesTypescript
  updBranch: BranchesTypescript
  branches: BranchesTypescript[]
  branchesByGroup: BranchesTypescript[]
  branchesFiltered: BranchesTypescript[]
  error: string | null
  editingId: string
}

const initialState: BranchState = {
  loading: false,
  loadingByGroup: false,
  loadingFiltered: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  addBranch: {
    _id: '',
    branchNumber: 0,
    corporateName: '',
    tradingName: '',
    identificationDocument: '',
    address: '',
    number: 0,
    district: '',
    city: '',
    zipCode: '',
    country: '',
    state: '',
    complement: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    latitude: 0,
    longitude: 0,
    idEconomicGroup: '',
    typeOfPerson: '',
    icon: '',

    result: false,
    message: '',
    err: '',
  },
  updBranch: {
    _id: '',
    branchNumber: 0,
    corporateName: '',
    tradingName: '',
    identificationDocument: '',
    address: '',
    number: 0,
    district: '',
    city: '',
    zipCode: '',
    country: '',
    state: '',
    complement: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    latitude: 0,
    longitude: 0,
    idEconomicGroup: '',
    typeOfPerson: '',
    icon: '',

    result: false,
    message: '',
    err: '',
  },
  branch: {
    _id: '',
    branchNumber: 0,
    corporateName: '',
    tradingName: '',
    identificationDocument: '',
    address: '',
    number: 0,
    district: '',
    city: '',
    zipCode: '',
    country: '',
    state: '',
    complement: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    latitude: 0,
    longitude: 0,
    idEconomicGroup: '',
    typeOfPerson: '',
    icon: '',

    result: false,
    message: '',
    err: '',
  },
  branches: [],
  branchesByGroup: [],
  branchesFiltered: [],
  error: null,
  editingId: '',
}

const branchReducer = (
  state = initialState,
  action: {
    type: string
    payload: BranchesTypescript[] | BranchesTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_BRANCHE_REQUEST:
      return {
        ...state,
        loading: true,
        branch: action.payload,
        addBranch: { ...action.payload, result: false, message: '' },
        updBranch: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ONE_BRANCHE_SUCCESS:
      return { ...state, branch: action.payload, loadingOne: false }

    case types.ONE_BRANCHE_FAILURE:
      return { ...state, loadingOne: false }

    case types.ALL_BRANCHES_REQUEST:
      return {
        ...state,
        loading: true,
        branch: action.payload,
        addBranch: { ...action.payload, result: false, message: '' },
        updBranch: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_BRANCHES_SUCCESS:
      return { ...state, branches: action.payload, loading: false }

    case types.ALL_BRANCHES_FAILURE:
      return state

    case types.ALL_BRANCHES_BY_USER_REQUEST:
      return {
        ...state,
        loading: true,
        branch: action.payload,
        addBranch: { ...action.payload, result: false, message: '' },
        updBranch: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_BRANCHES_BY_USER_SUCCESS:
      return { ...state, branches: action.payload, loading: false }

    case types.ALL_BRANCHES_BY_USER_FAILURE:
      return state

    case types.ALL_BRANCHES_BY_GROUP_REQUEST:
      return {
        ...state,
        loadingByGroup: true,
        branch: action.payload,
        addBranch: { ...action.payload, result: false, message: '' },
        updBranch: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_BRANCHES_BY_GROUP_SUCCESS:
      return {
        ...state,
        branchesByGroup: action.payload,
        loadingByGroup: false,
      }

    case types.ALL_BRANCHES_BY_GROUP_FAILURE:
      return state

    case types.ADD_BRANCH_REQUEST:
      return { ...state, addBranch: action.payload, loadingAdd: true }

    case types.ADD_BRANCH_SUCCESS:
      return { ...state, addBranch: action.payload, loadingAdd: false }

    case types.ADD_BRANCH_FAILURE:
      return { ...state, loadingAdd: false }

    case types.EDT_BRANCHE_REQUEST: {
      const obj: any = action.payload
      return {
        ...state,
        editingId: obj._id,
        economicGroup: initialState.branch,
        loadingUpd: false,
      }
    }

    case types.UPD_BRANCH_REQUEST:
      return { ...state, updBranch: action.payload, loadingUpd: true }

    case types.UPD_BRANCH_SUCCESS:
      return { ...state, updBranch: action.payload, loadingUpd: false }

    case types.UPD_BRANCH_FAILURE:
      return { ...state, updBranch: action.payload }

    case types.DEL_BRANCH_REQUEST:
      return state

    case types.DEL_BRANCH_SUCCESS:
      return { ...state }

    case types.DEL_BRANCH_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default branchReducer
