export const ONE_LAST_POSITION_REQUEST = 'ONE_LAST_POSITION_REQUEST'
export const ONE_LAST_POSITION_SUCCESS = 'ONE_LAST_POSITION_SUCCESS'
export const ONE_LAST_POSITION_FAILURE = 'ONE_LAST_POSITION_FAILURE'
export const ALL_LAST_POSITIONS_REQUEST = 'ALL_LAST_POSITIONS_REQUEST'
export const ALL_LAST_POSITIONS_SUCCESS = 'ALL_LAST_POSITIONS_SUCCESS'
export const ALL_LAST_POSITIONS_FAILURE = 'ALL_LAST_POSITIONS_FAILURE'
export const ADD_LAST_POSITION_REQUEST = 'ADD_LAST_POSITION_REQUEST'
export const ADD_LAST_POSITION_SUCCESS = 'ADD_LAST_POSITION_SUCCESS'
export const ADD_LAST_POSITION_FAILURE = 'ADD_LAST_POSITION_FAILURE'
export const DEL_LAST_POSITION_REQUEST = 'DEL_LAST_POSITION_REQUEST'
export const DEL_LAST_POSITION_SUCCESS = 'DEL_LAST_POSITION_SUCCESS'
export const DEL_LAST_POSITION_FAILURE = 'DEL_LAST_POSITION_FAILURE'
export const UPD_LAST_POSITION_REQUEST = 'UPD_LAST_POSITION_REQUEST'
export const UPD_LAST_POSITION_SUCCESS = 'UPD_LAST_POSITION_SUCCESS'
export const UPD_LAST_POSITION_FAILURE = 'UPD_LAST_POSITION_FAILURE'
