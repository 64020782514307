export const ONE_EVENT_TYPE_REQUEST = 'ONE_EVENT_TYPE_REQUEST'
export const ONE_EVENT_TYPE_SUCCESS = 'ONE_EVENT_TYPE_SUCCESS'
export const ONE_EVENT_TYPE_FAILURE = 'ONE_EVENT_TYPE_FAILURE'
export const ALL_EVENT_TYPES_REQUEST = 'ALL_EVENT_TYPES_REQUEST'
export const ALL_EVENT_TYPES_SUCCESS = 'ALL_EVENT_TYPES_SUCCESS'
export const ALL_EVENT_TYPES_FAILURE = 'ALL_EVENT_TYPES_FAILURE'
export const ADD_EVENT_TYPE_REQUEST = 'ADD_EVENT_TYPE_REQUEST'
export const ADD_EVENT_TYPE_SUCCESS = 'ADD_EVENT_TYPE_SUCCESS'
export const ADD_EVENT_TYPE_FAILURE = 'ADD_EVENT_TYPE_FAILURE'
export const DEL_EVENT_TYPE_REQUEST = 'DEL_EVENT_TYPE_REQUEST'
export const DEL_EVENT_TYPE_SUCCESS = 'DEL_EVENT_TYPE_SUCCESS'
export const DEL_EVENT_TYPE_FAILURE = 'DEL_EVENT_TYPE_FAILURE'
export const UPD_EVENT_TYPE_REQUEST = 'UPD_EVENT_TYPE_REQUEST'
export const UPD_EVENT_TYPE_SUCCESS = 'UPD_EVENT_TYPE_SUCCESS'
export const UPD_EVENT_TYPE_FAILURE = 'UPD_EVENT_TYPE_FAILURE'
