import { createSelector } from 'reselect'
import {
  DashboardWeatherTypescript,
  LastWeatherReadTypescript,
} from '../../../typescript'

// Getters
const getWeathers = (state: {
  weathers: { weathers: DashboardWeatherTypescript[] }
}) => state.weathers.weathers
const getLastWeatherRead = (state: {
  weathers: {
    lastWeatherRead: LastWeatherReadTypescript[]
  }
}) => state.weathers.lastWeatherRead
const getLastWeatherReadGrid = (state: {
  weathers: {
    lastWeatherReadGrid: LastWeatherReadTypescript[]
  }
}) => state.weathers.lastWeatherReadGrid
const getOneDashboardWeather = (state: {
  weathers: {
    oneDashboardWeather: DashboardWeatherTypescript
  }
}) => state.weathers.oneDashboardWeather

// Loadings
const getLoading = (state: { weathers: { loading: boolean } }) =>
  state.weathers.loading
const getOneDashboardLoading = (state: { weathers: { loadingOne: boolean } }) =>
  state.weathers.loadingOne
const getLastLoading = (state: { weathers: { loadingLast: boolean } }) =>
  state.weathers.loadingLast
const getLastGridLoading = (state: {
  weathers: { loadingLastGrid: boolean }
}) => state.weathers.loadingLastGrid

// exports
export const getWeathersSelector = createSelector(
  getWeathers,
  getLoading,
  (weathers: DashboardWeatherTypescript[]) => weathers,
)
export const getOneDashboardWeatherSelector = createSelector(
  getOneDashboardWeather,
  getOneDashboardLoading,
  (oneDashboardWeather: DashboardWeatherTypescript) => oneDashboardWeather,
)
export const getLastWeatherReadSelector = createSelector(
  getLastWeatherRead,
  getLastLoading,
  (lastWeatherRead: LastWeatherReadTypescript[]) => lastWeatherRead,
)
export const getLastWeatherReadGridSelector = createSelector(
  getLastWeatherReadGrid,
  getLastGridLoading,
  (lastWeatherReadGrid: LastWeatherReadTypescript[]) => lastWeatherReadGrid,
)

// Exports Loadings
export const getLoadingLastWeatherReadSelector = createSelector(
  getLastLoading,
  (loadingLast: boolean) => loadingLast,
)
// Exports Loadings
export const getLoadingLastWeatherReadGridSelector = createSelector(
  getLastGridLoading,
  (loadingLastGrid: boolean) => loadingLastGrid,
)
export const getLoadingOneDashboardWeatherSelector = createSelector(
  getOneDashboardLoading,
  (loadingOne: boolean) => loadingOne,
)
export const getLoadingWeatherSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
