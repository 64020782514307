/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { DeviceTypesTypescript } from '../../../typescript'
import * as DeviceTypesActions from '../../actions/deviceTypes'
import * as types from '../../types'

let allDeviceTypesApi: DeviceTypesTypescript[]
const allDeviceTypesRequisicao = async (deviceType: DeviceTypesTypescript) => {
  const query = `
  query {
    allDevicesType(find: {
      ${
        deviceType.description
          ? 'description: "' + deviceType.description + '"'
          : ''
      }
      ${deviceType.type ? 'type: "' + deviceType.type + '"' : ''}
      ${deviceType.area ? 'area: "' + deviceType.area + '"' : ''}
      ${
        deviceType.numberActuators
          ? 'numberActuators: ' + deviceType.numberActuators
          : ''
      }
    }) {
      _id
      type
      description
      area
      numberActuators
      humiditySensor
      result
      message
      err
    }
  }
      `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allDeviceTypesApi = data.data.allDevicesType
    return true
  })
}

let typesDeviceTypesApi: DeviceTypesTypescript[]
const typesDeviceTypesRequisicao = async (
  deviceType: DeviceTypesTypescript,
) => {
  const query = `
  query {
    allDevicesType(find: {}) {
      _id
      type
      description
      area
      numberActuators
      humiditySensor
      result
      message
      err
    }
  }
      `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    typesDeviceTypesApi = data.data.allDevicesType
    return true
  })
}

let oneDeviceTypeApi: DeviceTypesTypescript
const oneDeviceTypeRequisicao = async (deviceType: DeviceTypesTypescript) => {
  const query = `
  query {
    oneDeviceType( 
      ${deviceType._id ? '_id: "' + deviceType._id + '"' : ''}
    ) {
      _id
      type
      description
      area
      numberActuators
      humiditySensor
      result
      message
      err
    }
  }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneDeviceTypeApi = data.data.oneDeviceType
    return true
  })
}

let addDeviceTypesApi: DeviceTypesTypescript
const addDeviceTypeRequisicao = async (deviceType: DeviceTypesTypescript) => {
  const query = `
  mutation addDeviceType {
    createDevicesType(input: {
      type: "${deviceType.type}",
      description: "${deviceType.description}",
      area: "${deviceType.area}",
      numberActuators: ${deviceType.numberActuators},
      humiditySensor: ${deviceType.humiditySensor},
      }
    ) {
      result
      message
      err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    addDeviceTypesApi = data.data.createDevicesType
    return true
  })
}
let updDeviceTypesApi: DeviceTypesTypescript
const updateDeviceTypeRequisicao = async (
  deviceType: DeviceTypesTypescript,
) => {
  const query = `
  mutation updDeviceType {
    updateDevicesType(update: {
      _id: "${deviceType._id}",
      type: "${deviceType.type}",
      description: "${deviceType.description}",
      area: "${deviceType.area}",
      numberActuators: ${deviceType.numberActuators},
      humiditySensor: ${deviceType.humiditySensor},
      }
    ) {
      result
      message
      err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updDeviceTypesApi = data.data.updateDevicesType
    return true
  })
}

function* updDeviceType(action) {
  try {
    yield call(updateDeviceTypeRequisicao, action.payload)
    yield put(DeviceTypesActions.updDeviceTypeSuccess(updDeviceTypesApi))
  } catch {
    yield put(DeviceTypesActions.updDeviceTypeFailure(updDeviceTypesApi))
  }
}

function* allDeviceTypes(action) {
  try {
    yield call(allDeviceTypesRequisicao, action.payload)
    yield put(DeviceTypesActions.allDeviceTypesSuccess(allDeviceTypesApi))
  } catch {
    yield put(DeviceTypesActions.allDeviceTypesFailure())
  }
}

function* typesDeviceTypes(action) {
  try {
    yield call(typesDeviceTypesRequisicao, action.payload)
    yield put(DeviceTypesActions.typesDeviceTypesSuccess(typesDeviceTypesApi))
  } catch {
    yield put(DeviceTypesActions.typesDeviceTypesFailure())
  }
}

function* oneDeviceType(action) {
  try {
    yield call(oneDeviceTypeRequisicao, action.payload)
    yield put(DeviceTypesActions.oneDeviceTypeSuccess(oneDeviceTypeApi))
  } catch {
    yield put(DeviceTypesActions.oneDeviceTypeFailure())
  }
}

function* addDeviceType(action) {
  try {
    yield call(addDeviceTypeRequisicao, action.payload)
    yield put(DeviceTypesActions.addDeviceTypeSuccess(addDeviceTypesApi))
  } catch {
    yield put(DeviceTypesActions.addDeviceTypeFailure())
  }
}
export default all([
  takeLatest(types.ALL_DEVICE_TYPES_REQUEST, allDeviceTypes),
  takeLatest(types.TYPES_DEVICE_TYPES_REQUEST, typesDeviceTypes),
  takeLatest(types.ONE_DEVICE_TYPE_REQUEST, oneDeviceType),
  takeLatest(types.ADD_DEVICE_TYPE_REQUEST, addDeviceType),
  takeLatest(types.UPD_DEVICE_TYPE_REQUEST, updDeviceType),
])
