import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import {
  DashboardCardsDevicesTypescript,
  DashboardCardsGroupsTypescript,
  DashboardCardsTypescript,
} from '../../../typescript'
import * as DashboardCardsActions from '../../actions/dashboardCards'
import * as types from '../../types'

let dashboardCardsData: DashboardCardsTypescript
const dashboardCardsRequisicao = async (
  DashboardCards: DashboardCardsTypescript,
) => {
  const query = `
  query {
    DashboardCards(find:{
      flgActualGroup: ${DashboardCards.flgActualGroup}
      ${
        DashboardCards.branchNumber
          ? 'branchNumber: ' + DashboardCards.branchNumber
          : ''
      }
    }) {
      economigGroupsCount
      temperatureDevicesCount
      temperatureDevicesOnline
      temperatureDevicesOffline
      temperatureHighAlert
      temperatureLowAlert
      humidityHighAlert
      humidityLowAlert
      temperatureFailAlert
      temperatureInactive
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    dashboardCardsData = {
      ...data.data.DashboardCards,
      flgActualGroup: DashboardCards.flgActualGroup,
    }

    return true
  })
}

let dashboardCardsGroupsData: DashboardCardsGroupsTypescript[]
const dashboardCardsGroupsRequisicao = async (
  dashboardCardsGroups: DashboardCardsTypescript,
) => {
  await api({
    method: 'post',
    data: {
      query: `
      query {
        DashBoardCardsGroups(find: {
          flgActualGroup: ${dashboardCardsGroups.flgActualGroup}
          ${
            dashboardCardsGroups.branchNumber
              ? 'branchNumber: ' + dashboardCardsGroups.branchNumber
              : ''
          }
        }) {
          _id
          groupName
          identificationDocument
        }
      }
        `,
    },
  }).then(({ data }) => {
    dashboardCardsGroupsData = data.data.DashBoardCardsGroups
    return true
  })
}
let dashboardCardsDevicesData: DashboardCardsDevicesTypescript[]
const dashboardCardsDevicesRequisicao = async (
  dashboardCards: DashboardCardsTypescript,
) => {
  const query = `
      query {
        DashBoardCardsDevices(find: {
          flgActualGroup: ${dashboardCards.flgActualGroup}
          ${
            dashboardCards.branchNumber
              ? 'branchNumber: ' + dashboardCards.branchNumber
              : ''
          }
        }) {
          _id
          description
          serialNumber
          economicGroupDetail {
            groupNumber
            groupName
            tradingName
          }
          branchNumber
        }
      }
        `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    dashboardCardsDevicesData = data.data.DashBoardCardsDevices
    return true
  })
}

let dashboardCardsOnlineOfflineData: DashboardCardsTypescript[]
const dashboardCardsOnlineOfflineRequisicao = async (
  dashboardCardsOnlineOffline: DashboardCardsTypescript,
) => {
  const query = `
  query {
    DashBoardCardsOnlineOffLineDetail (find: 
    { 
      isOnLine: ${dashboardCardsOnlineOffline.isOnline}
      flgActualGroup: ${dashboardCardsOnlineOffline.flgActualGroup}
      ${
        dashboardCardsOnlineOffline.branchNumber
          ? 'branchNumber: ' + dashboardCardsOnlineOffline.branchNumber
          : ''
      }
        }) 
      {     
        _id  
        serialNumber
        description   
        branchNumber
        timeStamp   
        economicGroupDetail{
            groupName
            groupNumber
        } 
      
    }
  }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    dashboardCardsOnlineOfflineData =
      data.data.DashBoardCardsOnlineOffLineDetail

    return true
  })
}

let dashboardCardsAlertsFailData: DashboardCardsTypescript
// alertType => failAlert, highAlert. lowAlert and inactiveAlert
const dashboardCardsAlertsFailRequisicao = async (
  dashboardCardsAlertsFail: DashboardCardsTypescript,
) => {
  const query = `
  query {
    DashBoardCardsAlertsFailDetail (find: 
    { 
        alertType: "${dashboardCardsAlertsFail.alertType}"
        ${
          dashboardCardsAlertsFail.flgActualGroup
            ? 'flgActualGroup: ' + dashboardCardsAlertsFail.flgActualGroup
            : ''
        }
        ${
          dashboardCardsAlertsFail.serialNumber
            ? 'serialNumber: "' + dashboardCardsAlertsFail.serialNumber + '"'
            : ''
        }
        ${
          dashboardCardsAlertsFail.description
            ? 'description: "' + dashboardCardsAlertsFail.description + '"'
            : ''
        }
        ${
          dashboardCardsAlertsFail.branchNumber
            ? 'branchNumber: ' + dashboardCardsAlertsFail.branchNumber
            : ''
        }
                }) 
              {   
                _id  
                serialNumber
                description
                branchNumber
                temperatureOne
                temperatureTwo
                temperatureThree
                humidityOne
                humidityTwo
                minimumAlertOne
                maximumAlertOne
                minimumAlertTwo
                maximumAlertTwo
                minimumAlertThree
                maximumAlertThree
                timeStamp                      
                economicGroupDetail{
                    groupName
                    groupNumber
                } 
              
            }
          }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    dashboardCardsAlertsFailData = data.data.DashBoardCardsAlertsFailDetail

    return true
  })
}

function* dashboardCards(action) {
  try {
    yield call(dashboardCardsRequisicao, action.payload)
    yield put(DashboardCardsActions.DashboardCardsSuccess(dashboardCardsData))
  } catch {
    yield put(DashboardCardsActions.DashboardCardsFailure())
  }
}
function* dashboardCardsGroups(action) {
  try {
    yield call(dashboardCardsGroupsRequisicao, action.payload)
    yield put(
      DashboardCardsActions.DashboardCardsGroupsSuccess(
        dashboardCardsGroupsData,
      ),
    )
  } catch {
    yield put(DashboardCardsActions.DashboardCardsGroupsFailure())
  }
}
function* dashboardCardsDevices(action) {
  try {
    yield call(dashboardCardsDevicesRequisicao, action.payload)
    yield put(
      DashboardCardsActions.DashboardCardsDevicesSuccess(
        dashboardCardsDevicesData,
      ),
    )
  } catch {
    yield put(DashboardCardsActions.DashboardCardsDevicesFailure())
  }
}
function* dashboardCardsOnlineOffline(action) {
  try {
    yield call(dashboardCardsOnlineOfflineRequisicao, action.payload)
    yield put(
      DashboardCardsActions.DashboardCardsOnlineOfflineSuccess(
        dashboardCardsOnlineOfflineData,
      ),
    )
  } catch {
    yield put(DashboardCardsActions.DashboardCardsOnlineOfflineFailure())
  }
}
function* dashboardCardsAlertsFail(action) {
  try {
    yield call(dashboardCardsAlertsFailRequisicao, action.payload)
    yield put(
      DashboardCardsActions.DashboardCardsAlertsFailSuccess(
        dashboardCardsAlertsFailData,
      ),
    )
  } catch {
    yield put(DashboardCardsActions.DashboardCardsAlertsFailFailure())
  }
}

export default all([
  takeLatest(types.DASHBOARD_CARDS_REQUEST, dashboardCards),
  takeLatest(types.DASHBOARD_CARDS_GROUPS_REQUEST, dashboardCardsGroups),
  takeLatest(types.DASHBOARD_CARDS_DEVICES_REQUEST, dashboardCardsDevices),
  takeLatest(
    types.DASHBOARD_CARDS_ONLINE_OFFLINE_REQUEST,
    dashboardCardsOnlineOffline,
  ),
  takeLatest(
    types.DASHBOARD_CARDS_ALERTS_FAIL_REQUEST,
    dashboardCardsAlertsFail,
  ),
])
