import { SectionsTypescript } from '../../../typescript'
import * as types from '../../types'

export function oneSectionRequest(user: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.ONE_SECTION_REQUEST,
    payload: user,
  }
}
export function oneSectionSuccess(data: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.ONE_SECTION_SUCCESS,
    payload: data,
  }
}
export function oneSectionFailure(): { type: string } {
  return {
    type: types.ONE_SECTION_FAILURE,
  }
}

export function allSectionsRequest(param: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.ALL_SECTIONS_REQUEST,
    payload: param,
  }
}
export function allSectionsSuccess(data: SectionsTypescript[]): {
  type: string
  payload: SectionsTypescript[]
} {
  return {
    type: types.ALL_SECTIONS_SUCCESS,
    payload: data,
  }
}
export function allSectionsFailure(): { type: string } {
  return {
    type: types.ALL_SECTIONS_FAILURE,
  }
}

export function addSectionRequest(param: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.ADD_SECTION_REQUEST,
    payload: param,
  }
}
export function addSectionSuccess(data: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.ADD_SECTION_SUCCESS,
    payload: data,
  }
}
export function addSectionFailure(): { type: string } {
  return {
    type: types.ADD_SECTION_FAILURE,
  }
}

export function updSectionRequest(param: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.UPD_SECTION_REQUEST,
    payload: param,
  }
}
export function updSectionSuccess(data: SectionsTypescript[]): {
  type: string
  payload: SectionsTypescript[]
} {
  return {
    type: types.UPD_SECTION_SUCCESS,
    payload: data,
  }
}
export function updSectionFailure(data: SectionsTypescript): {
  type: string
  payload: SectionsTypescript
} {
  return {
    type: types.UPD_SECTION_FAILURE,
    payload: data,
  }
}
