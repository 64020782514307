export const ONE_PROFILE_REQUEST = 'ONE_PROFILE_REQUEST'
export const ONE_PROFILE_SUCCESS = 'ONE_PROFILE_SUCCESS'
export const ONE_PROFILE_FAILURE = 'ONE_PROFILE_FAILURE'
export const ALL_PROFILES_REQUEST = 'ALL_PROFILES_REQUEST'
export const ALL_PROFILES_SUCCESS = 'ALL_PROFILES_SUCCESS'
export const ALL_PROFILES_FAILURE = 'ALL_PROFILES_FAILURE'
export const ADD_PROFILE_REQUEST = 'ADD_PROFILE_REQUEST'
export const ADD_PROFILE_SUCCESS = 'ADD_PROFILE_SUCCESS'
export const ADD_PROFILE_FAILURE = 'ADD_PROFILE_FAILURE'
export const DEL_PROFILE_REQUEST = 'DEL_PROFILE_REQUEST'
export const DEL_PROFILE_SUCCESS = 'DEL_PROFILE_SUCCESS'
export const DEL_PROFILE_FAILURE = 'DEL_PROFILE_FAILURE'
export const UPD_PROFILE_REQUEST = 'UPD_PROFILE_REQUEST'
export const UPD_PROFILE_SUCCESS = 'UPD_PROFILE_SUCCESS'
export const UPD_PROFILE_FAILURE = 'UPD_PROFILE_FAILURE'
