/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  DashboardWeatherTypescript,
  WeatherTypescript,
} from '../../../typescript'
import * as types from '../../types'

export interface WeatherState {
  loading?: boolean
  loadingLast?: boolean
  loadingLastGrid?: boolean
  loadingOne?: boolean
  serialNumber: string
  weather: WeatherTypescript
  dashboardWeather: DashboardWeatherTypescript
  weathers: WeatherTypescript[]
  lastWeatherRead: WeatherTypescript[]
  lastWeatherReadGrid: WeatherTypescript[]
  error: string | null
}

const initialState: WeatherState = {
  loading: true,
  loadingLast: true,
  loadingLastGrid: true,
  loadingOne: false,
  serialNumber: '',
  weather: {
    _id: '',
    deviceId: '',
    timeStamp: '',
  },
  dashboardWeather: {
    device: {
      idEconomicGroup: '',
      branchNumber: 0,
      serialNumber: '',
    },
  },
  weathers: [],
  lastWeatherRead: [],
  lastWeatherReadGrid: [],
  error: null,
}

const weatherReducer = (
  state = initialState,
  action: {
    type: string
    payload: WeatherTypescript[] | WeatherTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_WEATHERS_REQUEST:
      return { ...state, loading: true }

    case types.ALL_WEATHERS_SUCCESS:
      return { ...state, weathers: action.payload, loading: false }

    case types.ALL_WEATHERS_FAILURE:
      return state

    case types.ONE_DASHBOARD_WEATHER_REQUEST:
      return { ...state, loadingOne: true, serialNumber: action.payload }

    case types.ONE_DASHBOARD_WEATHER_SUCCESS:
      return {
        ...state,
        oneDashboardWeather: action.payload,
        loadingOne: false,
      }

    case types.ONE_DASHBOARD_WEATHER_FAILURE:
      return { ...state, loadingOne: false }

    case types.LAST_WEATHERS_READ_REQUEST:
      return {
        ...state,
        loadingLast: true,
        dashboardWeather: action.payload,
      }

    case types.LAST_WEATHERS_READ_SUCCESS:
      return {
        ...state,
        lastWeatherRead: action.payload,
        loadingLast: false,
      }

    case types.LAST_WEATHERS_READ_FAILURE:
      return state

    case types.LAST_WEATHERS_READ_GRID_REQUEST:
      return {
        ...state,
        loadingLastGrid: true,
        dashboardWeather: action.payload,
      }

    case types.LAST_WEATHERS_READ_GRID_SUCCESS:
      return {
        ...state,
        lastWeatherReadGrid: action.payload,
        loadingLastGrid: false,
      }

    case types.LAST_WEATHERS_READ_GRID_FAILURE:
      return state

    case types.ADD_WEATHER_REQUEST:
      return { ...state, loading: true }

    case types.ADD_WEATHER_SUCCESS:
      return { ...state, loading: false }

    case types.ADD_WEATHER_FAILURE:
      return state

    case types.UPD_WEATHER_REQUEST:
      return { ...state, loading: true }

    case types.UPD_WEATHER_SUCCESS:
      return { ...state, loading: false }

    case types.UPD_WEATHER_FAILURE:
      return state

    case types.DEL_WEATHER_REQUEST:
      return state

    case types.DEL_WEATHER_SUCCESS:
      return { ...state }

    case types.DEL_WEATHER_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default weatherReducer
