import { EconomicGroupsTypescript } from '../../../typescript'
import * as types from '../../types'

export function oneEconomicGroupRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ONE_ECONOMIC_GROUP_REQUEST,
    payload: economicGroup,
  }
}
export function oneEconomicGroupSuccess(data: EconomicGroupsTypescript): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ONE_ECONOMIC_GROUP_SUCCESS,
    payload: data,
  }
}
export function oneEconomicGroupFailure(): { type: string } {
  return {
    type: types.ONE_ECONOMIC_GROUP_FAILURE,
  }
}

export function actualEconomicGroupRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ACTUAL_ECONOMIC_GROUP_REQUEST,
    payload: economicGroup,
  }
}
export function actualEconomicGroupReset(): {
  type: string
} {
  return {
    type: types.ACTUAL_ECONOMIC_GROUP_RESET,
  }
}
export function actualEconomicGroupSuccess(data: EconomicGroupsTypescript): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ACTUAL_ECONOMIC_GROUP_SUCCESS,
    payload: data,
  }
}
export function actualEconomicGroupFailure(): { type: string } {
  return {
    type: types.ACTUAL_ECONOMIC_GROUP_FAILURE,
  }
}

export function allEconomicGroupsAuthorizedRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST,
    payload: economicGroup,
  }
}
export function allEconomicGroupsAuthorizedSuccess(
  data: EconomicGroupsTypescript[],
): {
  type: string
  payload: EconomicGroupsTypescript[]
} {
  return {
    type: types.ALL_ECONOMIC_GROUPS_AUTHORIZED_SUCCESS,
    payload: data,
  }
}
export function allEconomicGroupsAuthorizedFailure(): { type: string } {
  return {
    type: types.ALL_ECONOMIC_GROUPS_AUTHORIZED_FAILURE,
  }
}

export function allEconomicGroupsRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ALL_ECONOMIC_GROUPS_REQUEST,
    payload: economicGroup,
  }
}
export function allEconomicGroupsSuccess(data: EconomicGroupsTypescript[]): {
  type: string
  payload: EconomicGroupsTypescript[]
} {
  return {
    type: types.ALL_ECONOMIC_GROUPS_SUCCESS,
    payload: data,
  }
}
export function allEconomicGroupsFailure(): { type: string } {
  return {
    type: types.ALL_ECONOMIC_GROUPS_FAILURE,
  }
}

export function allEconomicGroupsToResellersRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST,
    payload: economicGroup,
  }
}
export function allEconomicGroupsToResellersSuccess(
  data: EconomicGroupsTypescript[],
): {
  type: string
  payload: EconomicGroupsTypescript[]
} {
  return {
    type: types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_SUCCESS,
    payload: data,
  }
}
export function allEconomicGroupsToResellersFailure(): { type: string } {
  return {
    type: types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_FAILURE,
  }
}

export function addEconomicGroupRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ADD_ECONOMIC_GROUP_REQUEST,
    payload: economicGroup,
  }
}
export function addEconomicGroupSuccess(data: EconomicGroupsTypescript): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.ADD_ECONOMIC_GROUP_SUCCESS,
    payload: data,
  }
}
export function addEconomicGroupFailure(): { type: string } {
  return {
    type: types.ADD_ECONOMIC_GROUP_FAILURE,
  }
}

export function edtEconomicGroupRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.EDT_ECONOMIC_GROUP_REQUEST,
    payload: economicGroup,
  }
}

export function updEconomicGroupRequest(
  economicGroup: EconomicGroupsTypescript,
): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.UPD_ECONOMIC_GROUP_REQUEST,
    payload: economicGroup,
  }
}

export function updEconomicGroupSuccess(data: EconomicGroupsTypescript[]): {
  type: string
  payload: EconomicGroupsTypescript[]
} {
  return {
    type: types.UPD_ECONOMIC_GROUP_SUCCESS,
    payload: data,
  }
}
export function updEconomicGroupFailure(data: EconomicGroupsTypescript): {
  type: string
  payload: EconomicGroupsTypescript
} {
  return {
    type: types.UPD_ECONOMIC_GROUP_FAILURE,
    payload: data,
  }
}
