import { MenusTypescript, NewProfilesTypescript } from '../../../typescript'
import * as types from '../../types'

export function allNewProfilesRequest(newProfile: NewProfilesTypescript): {
  type: string
  payload: NewProfilesTypescript
} {
  return {
    type: types.ALL_NEW_PROFILES_REQUEST,
    payload: newProfile,
  }
}
export function allNewProfilesSuccess(data: NewProfilesTypescript[]): {
  type: string
  payload: NewProfilesTypescript[]
} {
  return {
    type: types.ALL_NEW_PROFILES_SUCCESS,
    payload: data,
  }
}
export function allNewProfilesFailure(): { type: string } {
  return {
    type: types.ALL_NEW_PROFILES_FAILURE,
  }
}

export function allNewProfilesTreeRequest(newProfile: any): {
  type: string
  payload: any
} {
  return {
    type: types.ALL_NEW_PROFILES_TREE_REQUEST,
    payload: newProfile,
  }
}
export function allNewProfilesTreeSuccess(data: MenusTypescript[]): {
  type: string
  payload: MenusTypescript[]
} {
  return {
    type: types.ALL_NEW_PROFILES_TREE_SUCCESS,
    payload: data,
  }
}
export function allNewProfilesTreeFailure(): { type: string } {
  return {
    type: types.ALL_NEW_PROFILES_TREE_FAILURE,
  }
}

export function addNewProfileRequest(newProfile: NewProfilesTypescript): {
  type: string
  payload: NewProfilesTypescript
} {
  return {
    type: types.ADD_NEW_PROFILE_REQUEST,
    payload: newProfile,
  }
}
export function addNewProfileSuccess(data: NewProfilesTypescript): {
  type: string
  payload: NewProfilesTypescript
} {
  return {
    type: types.ADD_NEW_PROFILE_SUCCESS,
    payload: data,
  }
}
export function addNewProfileFailure(): { type: string } {
  return {
    type: types.ADD_NEW_PROFILE_FAILURE,
  }
}

export function updNewProfileRequest(newProfile: NewProfilesTypescript): {
  type: string
  payload: NewProfilesTypescript
} {
  return {
    type: types.UPD_NEW_PROFILE_REQUEST,
    payload: newProfile,
  }
}
export function updNewProfileSuccess(data: NewProfilesTypescript[]): {
  type: string
  payload: NewProfilesTypescript[]
} {
  return {
    type: types.UPD_NEW_PROFILE_SUCCESS,
    payload: data,
  }
}
export function updNewProfileFailure(data: NewProfilesTypescript): {
  type: string
  payload: NewProfilesTypescript
} {
  return {
    type: types.UPD_NEW_PROFILE_FAILURE,
    payload: data,
  }
}
