export const ONE_DEPARTMENT_REQUEST = 'ONE_DEPARTMENT_REQUEST'
export const ONE_DEPARTMENT_SUCCESS = 'ONE_DEPARTMENT_SUCCESS'
export const ONE_DEPARTMENT_FAILURE = 'ONE_DEPARTMENT_FAILURE'
export const ALL_DEPARTMENTS_REQUEST = 'ALL_DEPARTMENTS_REQUEST'
export const ALL_DEPARTMENTS_SUCCESS = 'ALL_DEPARTMENTS_SUCCESS'
export const ALL_DEPARTMENTS_FAILURE = 'ALL_DEPARTMENTS_FAILURE'
export const ADD_DEPARTMENT_REQUEST = 'ADD_DEPARTMENT_REQUEST'
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS'
export const ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE'
export const DEL_DEPARTMENT_REQUEST = 'DEL_DEPARTMENT_REQUEST'
export const DEL_DEPARTMENT_SUCCESS = 'DEL_DEPARTMENT_SUCCESS'
export const DEL_DEPARTMENT_FAILURE = 'DEL_DEPARTMENT_FAILURE'
export const UPD_DEPARTMENT_REQUEST = 'UPD_DEPARTMENT_REQUEST'
export const UPD_DEPARTMENT_SUCCESS = 'UPD_DEPARTMENT_SUCCESS'
export const UPD_DEPARTMENT_FAILURE = 'UPD_DEPARTMENT_FAILURE'
