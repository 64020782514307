import { createSelector } from 'reselect'
import { DeviceTypesTypescript } from '../../../typescript'

// Getters
const getDeviceTypes = (state: {
  deviceTypes: { deviceTypes: DeviceTypesTypescript[] }
}) => state.deviceTypes.deviceTypes
const getDeviceToSearchTypes = (state: {
  deviceTypes: { deviceTypesToSearch: DeviceTypesTypescript[] }
}) => state.deviceTypes.deviceTypesToSearch
const addDeviceType = (state: {
  deviceTypes: { addDeviceType: DeviceTypesTypescript }
}) => state.deviceTypes.addDeviceType
const updDeviceType = (state: {
  deviceTypes: { updDeviceType: DeviceTypesTypescript }
}) => state.deviceTypes.updDeviceType
const getDeviceType = (state: {
  deviceTypes: { deviceType: DeviceTypesTypescript }
}) => state.deviceTypes.deviceType

// Loadings
const getLoading = (state: { deviceTypes: { loading: boolean } }) =>
  state.deviceTypes.loading
const getLoadingToSearch = (state: {
  deviceTypes: { loadingToSearch: boolean }
}) => state.deviceTypes.loadingToSearch
const addLoading = (state: { deviceTypes: { loadingAdd: boolean } }) =>
  state.deviceTypes.loadingAdd
const updLoading = (state: { deviceTypes: { loadingUpd: boolean } }) =>
  state.deviceTypes.loadingUpd
const getOneLoading = (state: { deviceTypes: { loadingOne: boolean } }) =>
  state.deviceTypes.loadingOne

// exports
export const getDeviceTypesSelector = createSelector(
  getDeviceTypes,
  getLoading,
  (deviceTypes: DeviceTypesTypescript[]) => deviceTypes,
)
// exports
export const getDeviceToSearchTypesSelector = createSelector(
  getDeviceToSearchTypes,
  getLoadingToSearch,
  (deviceToSearchTypes: DeviceTypesTypescript[]) => deviceToSearchTypes,
)
export const getDeviceTypeSelector = createSelector(
  getDeviceType,
  getOneLoading,
  (deviceType: DeviceTypesTypescript) => deviceType,
)
export const addDeviceTypeSelector = createSelector(
  addDeviceType,
  addLoading,
  (addDeviceType: DeviceTypesTypescript) => addDeviceType,
)
export const updDeviceTypeSelector = createSelector(
  updDeviceType,
  updLoading,
  (updDeviceType: DeviceTypesTypescript) => updDeviceType,
)

// Exports Loadings
export const getLoadingDeviceTypesSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingToSearchDeviceTypesSelector = createSelector(
  getLoadingToSearch,
  (loadingToSearch: boolean) => loadingToSearch,
)
export const getLoadingDeviceTypeSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddDeviceTypeSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdDeviceTypeSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
