import { MenusTypescript } from '../../../typescript'
import * as types from '../../types'

export interface MenuState {
  loading: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  menu: MenusTypescript
  menus: MenusTypescript[]
  addMenu: MenusTypescript
  updMenu: MenusTypescript
  error: string | null
}

const initialState: MenuState = {
  loading: true,
  loadingOne: true,
  loadingAdd: true,
  loadingUpd: true,
  addMenu: {
    _id: '',
    menuId: undefined,
    description: '',
    path: '',
    rootMenu: 0,
    status: 0,
    position: 0,
    result: false,
    message: '',
    err: '',
  },
  updMenu: {
    _id: '',
    menuId: undefined,
    description: '',
    path: '',
    rootMenu: 0,
    status: 0,
    position: 0,
    result: false,
    message: '',
    err: '',
  },
  menu: {
    _id: '',
    menuId: undefined,
    description: '',
    path: '',
    rootMenu: 0,
    status: 0,
    position: 0,
    result: false,
    message: '',
    err: '',
  },
  menus: [],
  error: null,
}

const menusReducer = (
  state = initialState,
  action: {
    type: string
    payload: MenusTypescript[] | MenusTypescript
  },
) => {
  switch (action.type) {
    case types.ALL_MENUS_REQUEST:
      return {
        ...state,
        loading: true,
        menu: action.payload,
        addMenu: { ...action.payload, result: false, message: '' },
        updMenu: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_MENUS_SUCCESS:
      return { ...state, menus: action.payload, loading: false }

    case types.ALL_MENUS_FAILURE:
      return state

    case types.ADD_MENU_REQUEST:
      return { ...state, addMenu: action.payload, loadingAdd: true }

    case types.ADD_MENU_SUCCESS:
      return { ...state, addMenu: action.payload, loadingAdd: false }

    case types.ADD_MENU_FAILURE:
      return state

    case types.UPD_MENU_REQUEST:
      return { ...state, updMenu: action.payload, loadingUpd: true }

    case types.UPD_MENU_SUCCESS:
      return { ...state, updMenu: action.payload, loadingUpd: false }

    case types.UPD_MENU_FAILURE:
      return { ...state, updMenu: action.payload }

    case types.DEL_MENU_REQUEST:
      return state

    case types.DEL_MENU_SUCCESS:
      return { ...state }

    case types.DEL_MENU_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default menusReducer
