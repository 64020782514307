export const ONE_DEVICE_TYPE_REQUEST = 'ONE_DEVICE_TYPE_REQUEST'
export const ONE_DEVICE_TYPE_SUCCESS = 'ONE_DEVICE_TYPE_SUCCESS'
export const ONE_DEVICE_TYPE_FAILURE = 'ONE_DEVICE_TYPE_FAILURE'
export const ALL_DEVICE_TYPES_REQUEST = 'ALL_DEVICES_TYPE_REQUEST'
export const ALL_DEVICE_TYPES_SUCCESS = 'ALL_DEVICES_TYPE_SUCCESS'
export const ALL_DEVICE_TYPES_FAILURE = 'ALL_DEVICES_TYPE_FAILURE'
export const TYPES_DEVICE_TYPES_REQUEST = 'TYPES_DEVICES_TYPE_REQUEST'
export const TYPES_DEVICE_TYPES_SUCCESS = 'TYPES_DEVICES_TYPE_SUCCESS'
export const TYPES_DEVICE_TYPES_FAILURE = 'TYPES_DEVICES_TYPE_FAILURE'
export const ADD_DEVICE_TYPE_REQUEST = 'ADD_DEVICE_TYPE_REQUEST'
export const ADD_DEVICE_TYPE_SUCCESS = 'ADD_DEVICE_TYPE_SUCCESS'
export const ADD_DEVICE_TYPE_FAILURE = 'ADD_DEVICE_TYPE_FAILURE'
export const DEL_DEVICE_TYPE_REQUEST = 'DEL_DEVICE_TYPE_REQUEST'
export const DEL_DEVICE_TYPE_SUCCESS = 'DEL_DEVICE_TYPE_SUCCESS'
export const DEL_DEVICE_TYPE_FAILURE = 'DEL_DEVICE_TYPE_FAILURE'
export const UPD_DEVICE_TYPE_REQUEST = 'UPD_DEVICE_TYPE_REQUEST'
export const UPD_DEVICE_TYPE_SUCCESS = 'UPD_DEVICE_TYPE_SUCCESS'
export const UPD_DEVICE_TYPE_FAILURE = 'UPD_DEVICE_TYPE_FAILURE'
