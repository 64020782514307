import { SystemLogsTypescript } from '../../../../typescript/logs'
import * as types from '../../../types'

export interface SystemLogsState {
  loading: boolean
  systemLogs: SystemLogsTypescript
  error: string | null
}

const initialState: SystemLogsState = {
  loading: false,
  systemLogs: {
    startTimeStamp: '',
    stopTimeStamp: '',
    statusCode: 0,
    requisition: '',
    remoteAddress: '',
    timeStamp: '',
    user: '',
    answer: '',
    result: false,
    message: '',
    err: '',
  },
  error: null,
}

export const systemLogsReducer = (
  state = initialState,
  action: {
    type: string
    payload: SystemLogsTypescript
  },
) => {
  switch (action.type) {
    // Detalhado
    case types.SYSTEM_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
      }

    case types.SYSTEM_LOGS_SUCCESS:
      return { ...state, data: action.payload, loading: false }

    case types.SYSTEM_LOGS_FAILURE:
      return { ...state, data: action.payload, loading: false }

    default:
      return state
  }
}
