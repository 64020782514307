import { createSelector } from 'reselect'
import {
  DashboardTemperatureTypescript,
  LastTemperatureReadTypescript,
} from '../../../typescript'

// Getters
const getTemperatures = (state: {
  temperatures: { temperatures: DashboardTemperatureTypescript[] }
}) => state.temperatures.temperatures
const getLastTemperaturesRead = (state: {
  temperatures: {
    lastTemperaturesRead: LastTemperatureReadTypescript[]
  }
}) => state.temperatures.lastTemperaturesRead
const getLastTemperaturesReadGrid = (state: {
  temperatures: {
    lastTemperaturesReadGrid: LastTemperatureReadTypescript[]
  }
}) => state.temperatures.lastTemperaturesReadGrid
const getOneDashboardTemperature = (state: {
  temperatures: {
    oneDashboardTemperature: DashboardTemperatureTypescript[]
  }
}) => state.temperatures.oneDashboardTemperature

// Loadings
const getLoading = (state: { temperatures: { loading: boolean } }) =>
  state.temperatures.loading
const getOneDashboardLoading = (state: {
  temperatures: { loadingOne: boolean }
}) => state.temperatures.loadingOne
const getLastLoading = (state: { temperatures: { loadingLast: boolean } }) =>
  state.temperatures.loadingLast
const getLastGridLoading = (state: {
  temperatures: { loadingLastGrid: boolean }
}) => state.temperatures.loadingLastGrid

// exports
export const getTemperaturesSelector = createSelector(
  getTemperatures,
  getLoading,
  (temperatures: DashboardTemperatureTypescript[]) => temperatures,
)
export const getOneDashboardTemperatureSelector = createSelector(
  getOneDashboardTemperature,
  getOneDashboardLoading,
  (oneDashboardTemperature: DashboardTemperatureTypescript[]) =>
    oneDashboardTemperature,
)
export const getLastTemperaturesReadSelector = createSelector(
  getLastTemperaturesRead,
  getLastLoading,
  (lastTemperaturesRead: LastTemperatureReadTypescript[]) =>
    lastTemperaturesRead,
)
export const getLastTemperaturesReadGridSelector = createSelector(
  getLastTemperaturesReadGrid,
  getLastGridLoading,
  (lastTemperaturesReadGrid: LastTemperatureReadTypescript[]) =>
    lastTemperaturesReadGrid,
)

// Exports Loadings
export const getLoadingLastTemperaturesReadSelector = createSelector(
  getLastLoading,
  (loadingLast: boolean) => loadingLast,
)
// Exports Loadings
export const getLoadingLastTemperaturesReadGridSelector = createSelector(
  getLastGridLoading,
  (loadingLastGrid: boolean) => loadingLastGrid,
)
export const getLoadingOneDashboardTemperatureSelector = createSelector(
  getOneDashboardLoading,
  (loadingOne: boolean) => loadingOne,
)
export const getLoadingTemperatureSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
