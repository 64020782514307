import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import {
  DashboardWeatherTypescript,
  LastWeatherReadTypescript,
  WeatherTypescript,
} from '../../../typescript'
import * as WeathersActions from '../../actions/weathers'
import * as types from '../../types'

const oneWeatherRequisicao = () =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, 2000)
  })

let oneDashboardWeatherApi: DashboardWeatherTypescript[]
const oneDashboardWeatherRequisicao = async (serialNumber: string) => {
  const query = `
  query {
    dashboardWeather(limit: ${60} serialNumberDevice: "${serialNumber}") {
      device {
        _id
        description
        serialNumber
        latitude
        longitude
        inputNameOne
        inputNameTwo
        inputNameThree
        temperatureNameOne
        temperatureNameTwo
        temperatureNameThree
        minimumRangeOne              
        maximumRangeOne
        minimumRangeTwo              
        maximumRangeTwo
        minimumRangeThree
        maximumRangeThree
        minimumAlertOne
        maximumAlertOne
        minimumAlertTwo
        maximumAlertTwo
        minimumAlertThree
        maximumAlertThree         
            minimumRangeHumidity
              maximumRangeHumidity
              minimumAlertHumidity
              maximumAlertHumidity       
        tActiveOne
        tActiveTwo
        tActiveThree
      }  
      weatherPositions {
        _id
        temperatureOne
        temperatureTwo
        timeStamp
        atmosphericPressure
        sealevelPressure
        windSpeed
        windDirection
        precipitationRain
        solarRadiation
        sunshine
        humidity
        altitude
        actualAltitude
      }
      records
      openings
      minimumOne
      averageOne
      maximumOne
      minimumTwo
      averageTwo
      maximumTwo
      minimumThree
      averageThree
      maximumThree
      minimumAtmosphericPressure
      averageAtmosphericPressure
      maximumAtmosphericPressure
      minimumWindSpeed
      averageWindSpeed
      maximumWindSpeed
      minimumWindDirection
      averageWindDirection
      maximumWindDirection
      minimumPrecipitationRain
      averagePrecipitationRain
      maximumPrecipitationRain
      minimumSolarRadiation
      averageSolarRadiation
      maximumSolarRadiation
      minimumSunshine
      averageSunshine
      maximumSunshine
      minimumHumidity
      averageHumidity
      maximumHumidity
      minimumAltitude
      averageAltitude
      maximumAltitude
      minimumActualAltitude
      averageActualAltitude
      maximumActualAltitude
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneDashboardWeatherApi = data.data.dashboardWeather[0]

    return true
  })
}

let allWeathersApi: DashboardWeatherTypescript[]
const allWeathersRequisicao = async (serialNumber: string) => {
  await api({
    method: 'post',
    data: {
      query: `
        query {
          dashboardWeather(limit: ${60} serialNumberDevice: "${serialNumber}") {
            device {
              _id
              description
              serialNumber
              latitude
              longitude
              inputNameOne
              inputNameTwo
              inputNameThree
              temperatureNameOne
              temperatureNameTwo
              temperatureNameThree
              minimumRangeOne              
              maximumRangeOne
              minimumRangeTwo              
              maximumRangeTwo
              minimumRangeThree
              maximumRangeThree
              minimumAlertOne
              maximumAlertOne
              minimumAlertTwo
              maximumAlertTwo
              minimumAlertThree
              maximumAlertThree               
              minimumRangeHumidity
              maximumRangeHumidity
              minimumAlertHumidity
              maximumAlertHumidity       
              tActiveOne
              tActiveTwo
              tActiveThree
            }  
            weatherPositions {
              _id
              temperatureOne
              temperatureTwo
              timeStamp
              atmosphericPressure
              sealevelPressure
              windSpeed
              windDirection
              precipitationRain
              solarRadiation
              sunshine
              humidity
              altitude
              actualAltitude
            }
            records
            openings
            minimumOne
            averageOne
            maximumOne
            minimumTwo
            averageTwo
            maximumTwo
            minimumThree
            averageThree
            maximumThree
            minimumAtmosphericPressure
            averageAtmosphericPressure
            maximumAtmosphericPressure
            minimumWindSpeed
            averageWindSpeed
            maximumWindSpeed
            minimumWindDirection
            averageWindDirection
            maximumWindDirection
            minimumPrecipitationRain
            averagePrecipitationRain
            maximumPrecipitationRain
            minimumSolarRadiation
            averageSolarRadiation
            maximumSolarRadiation
            minimumSunshine
            averageSunshine
            maximumSunshine
            minimumHumidity
            averageHumidity
            maximumHumidity
            minimumAltitude
            averageAltitude
            maximumAltitude
            minimumActualAltitude
            averageActualAltitude
            maximumActualAltitude
          }
        }
        `,
    },
  }).then(({ data }) => {
    allWeathersApi = data.data.dashboardWeather

    return true
  })
}

let lastWeatherReadApi: DashboardWeatherTypescript[]
const lastWeathersRequisicao = async (
  dashboardWeather: DashboardWeatherTypescript,
) => {
  const query = `
        query {
          lastWeatherRead(find:{
            ${
              dashboardWeather.device?.idEconomicGroup !== undefined
                ? 'idEconomicGroup: "' +
                  dashboardWeather.device.idEconomicGroup +
                  '"'
                : ''
            }
            ${
              dashboardWeather.device?.branchNumber !== undefined
                ? 'branchNumber: ' + dashboardWeather.device.branchNumber
                : ''
            }
            ${
              dashboardWeather.device?.serialNumber !== undefined
                ? 'serialNumber: "' + dashboardWeather.device.serialNumber + '"'
                : ''
            }
          }) {
              _id
              deviceId
              device {
                deviceType
                description
                serialNumber
                branchNumber
                latitude
                latitude
                latitude
                longitude
                inputNameOne
                inputNameTwo
                inputNameThree
                temperatureNameOne
                temperatureNameTwo
                temperatureNameThree
                economicGroupDetail{
                 _id
                 groupName
                 groupNumber
               }
               tActiveOne
              tActiveTwo
              tActiveThree
              }
              temperatureOne
              temperatureTwo
              timeStamp
              atmosphericPressure
              sealevelPressure
              windSpeed
              windDirection
              precipitationRain
              solarRadiation
              sunshine
              humidity
              altitude
              actualAltitude
              numberOfRows
          }
     }
          `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    lastWeatherReadApi = data.data.lastWeatherRead

    return true
  })
}

let lastWeatherReadGridApi: DashboardWeatherTypescript[]
const lastWeathersGridRequisicao = async (
  params: LastWeatherReadTypescript,
) => {
  const query = `
        query {
          lastWeatherRead(find:{
           
            ${
              params?.device?.idEconomicGroup !== undefined
                ? 'idEconomicGroup: "' + params?.device?.idEconomicGroup + '"'
                : ''
            }
            ${
              params?.device?.branchNumber !== undefined
                ? 'branchNumber: ' + params?.device?.branchNumber
                : ''
            }
            ${
              params?.device?.serialNumber !== undefined
                ? 'serialNumber: "' + params?.device?.serialNumber + '"'
                : ''
            }
          }) {
            _id
            deviceId
            device {
              deviceType
              description
              serialNumber
              branchNumber
              latitude
              latitude
              latitude
              longitude
              inputNameOne
              inputNameTwo
              inputNameThree
              temperatureNameOne
              temperatureNameTwo
              temperatureNameThree
              economicGroupDetail{
               _id
               groupName
               groupNumber
             }
             tActiveOne
            tActiveTwo
            tActiveThree
            }
            temperatureOne
            temperatureTwo
            timeStamp
            atmosphericPressure
            sealevelPressure
            windSpeed
            windDirection
            precipitationRain
            solarRadiation
            sunshine
            humidity
            altitude
            actualAltitude
            numberOfRows
        }
     }
          `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    lastWeatherReadGridApi = data.data.lastWeatherRead

    return true
  })
}

const addWeatherRequisicao = async (action: any) => {
  const weather: WeatherTypescript = action.payload
  await api({
    method: 'post',
    data: {
      query: `
        mutation addTemperature {
          createTemperatures(
            temperatureOne: "${weather.temperatureOne}"
            temperatureTwo: "${weather.temperatureTwo}"
            timeStamp: "${weather.timeStamp}"
            )
            {
            _id
            deviceId
            timeStamp
            temperatureOne
            temperatureTwo
            temperatureThree
            inputOne
            inputTwo
            inputThree
            
          }
        }
      `,
    },
  }).then(() => {
    // addDevicesApi = data.data.createDevices
    return true
  })
}

function* oneWeather() {
  try {
    yield call(oneWeatherRequisicao)
    yield put(WeathersActions.oneWeatherSuccess())
  } catch {
    yield put(WeathersActions.oneWeatherFailure())
  }
}
function* oneDashboardWeather(action) {
  try {
    yield call(oneDashboardWeatherRequisicao, action.payload)
    yield put(
      WeathersActions.oneDashboardWeatherSuccess(oneDashboardWeatherApi),
    )
  } catch {
    yield put(WeathersActions.oneDashboardWeatherFailure())
  }
}

function* allWeathers(action) {
  try {
    yield call(allWeathersRequisicao, action.payload)
    yield put(WeathersActions.allWeathersSuccess(allWeathersApi))
  } catch {
    yield put(WeathersActions.allWeathersFailure())
  }
}
function* lastWeatherRead(action) {
  try {
    yield call(lastWeathersRequisicao, action.payload)
    yield put(WeathersActions.lastWeatherReadSuccess(lastWeatherReadApi))
  } catch {
    yield put(WeathersActions.lastWeatherReadFailure())
  }
}
function* lastWeatherReadGrid(action) {
  try {
    yield call(lastWeathersGridRequisicao, action.payload)
    yield put(
      WeathersActions.lastWeatherReadGridSuccess(lastWeatherReadGridApi),
    )
  } catch {
    yield put(WeathersActions.lastWeatherReadGridFailure())
  }
}

export default all([
  takeLatest(types.ONE_WEATHER_REQUEST, oneWeather),
  takeLatest(types.ALL_WEATHERS_REQUEST, allWeathers),
  takeLatest(types.ONE_DASHBOARD_WEATHER_REQUEST, oneDashboardWeather),
  takeLatest(types.LAST_WEATHERS_READ_REQUEST, lastWeatherRead),
  takeLatest(types.LAST_WEATHERS_READ_GRID_REQUEST, lastWeatherReadGrid),
  takeLatest(types.ADD_WEATHER_REQUEST, addWeatherRequisicao),
])
