import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { MenusTypescript } from '../../../typescript'
import * as MenusActions from '../../actions/menus'
import * as types from '../../types'

let allMenusApi: MenusTypescript[]
const allMenusRequisicao = async (menu: MenusTypescript) => {
  const query = `
  query {
    allMenus(find: {
      ${menu.menuId ? 'menuId: ' + menu.menuId : ''}
      ${menu.rootMenu ? 'rootMenu: ' + menu.rootMenu : ''}
      ${menu.description ? 'description: "' + menu.description + '"' : ''}
      ${menu.position ? 'position: ' + menu.position : ''}
      ${menu.path ? 'path: "' + menu.path + '"' : ''}
      ${menu._id ? '_id: ' + menu._id : ''}
    }) {
        _id
        menuId
        description
        path
        icon
        rootMenu
        status
        position
        result
        message
        err
    }
  }
    `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allMenusApi = data.data.allMenus
    return true
  })
}

let addMenuApi: MenusTypescript
const addMenuRequisicao = async (menu: MenusTypescript) => {
  const query = `
  mutation {
    createMenus(input: {
      description: "${menu.description}"
      path: "${menu.path}"
      icon: "${menu.icon}"
      rootMenu: ${menu.rootMenu}
      status: ${menu.status}
      position: ${menu.position}
    }) {
      result
      message
      err
    }
  }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      addMenuApi = data.data.createMenus

      return true
    })
    .catch((e) => e)
}
let updMenuApi: MenusTypescript[]
const updMenuRequisicao = async (menu: MenusTypescript) => {
  const query = `
        mutation {
          updateMenus(update:{
            _id: "${menu._id}",
            description: "${menu.description}"
            path: "${menu.path}"
            icon: "${menu.icon}"
            rootMenu: ${menu.rootMenu}
            status: ${menu.status}
            position: ${menu.position}
          })
            {
            result
            message
            err
          }
        }
      `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updMenuApi = data.data.updateMenus

    return true
  })
}

function* allMenus(action) {
  try {
    yield call(allMenusRequisicao, action.payload)
    yield put(MenusActions.allMenusSuccess(allMenusApi))
  } catch {
    yield put(MenusActions.allMenusFailure())
  }
}

function* addMenu(action) {
  try {
    yield call(addMenuRequisicao, action.payload)
    yield put(MenusActions.addMenuSuccess(addMenuApi))
  } catch {
    yield put(MenusActions.addMenuFailure())
  }
}

function* updMenu(action) {
  try {
    yield call(updMenuRequisicao, action.payload)
    yield put(MenusActions.updMenuSuccess(updMenuApi))
  } catch {
    yield put(MenusActions.updMenuFailure(updMenuApi))
  }
}
export default all([
  takeLatest(types.ALL_MENUS_REQUEST, allMenus),
  takeLatest(types.ADD_MENU_REQUEST, addMenu),
  takeLatest(types.UPD_MENU_REQUEST, updMenu),
])
