import { ProfilesTypescript } from '../../../typescript'
import * as types from '../../types'

export function allProfilesRequest(profile: ProfilesTypescript): {
  type: string
  payload: ProfilesTypescript
} {
  return {
    type: types.ALL_PROFILES_REQUEST,
    payload: profile,
  }
}
export function allProfilesSuccess(data: ProfilesTypescript[]): {
  type: string
  payload: ProfilesTypescript[]
} {
  return {
    type: types.ALL_PROFILES_SUCCESS,
    payload: data,
  }
}
export function allProfilesFailure(): { type: string } {
  return {
    type: types.ALL_PROFILES_FAILURE,
  }
}

export function addProfileRequest(profile: ProfilesTypescript): {
  type: string
  payload: ProfilesTypescript
} {
  return {
    type: types.ADD_PROFILE_REQUEST,
    payload: profile,
  }
}
export function addProfileSuccess(data: ProfilesTypescript): {
  type: string
  payload: ProfilesTypescript
} {
  return {
    type: types.ADD_PROFILE_SUCCESS,
    payload: data,
  }
}
export function addProfileFailure(): { type: string } {
  return {
    type: types.ADD_PROFILE_FAILURE,
  }
}

export function updProfileRequest(profile: ProfilesTypescript): {
  type: string
  payload: ProfilesTypescript
} {
  return {
    type: types.UPD_PROFILE_REQUEST,
    payload: profile,
  }
}
export function updProfileSuccess(data: ProfilesTypescript[]): {
  type: string
  payload: ProfilesTypescript[]
} {
  return {
    type: types.UPD_PROFILE_SUCCESS,
    payload: data,
  }
}
export function updProfileFailure(data: ProfilesTypescript): {
  type: string
  payload: ProfilesTypescript
} {
  return {
    type: types.UPD_PROFILE_FAILURE,
    payload: data,
  }
}
