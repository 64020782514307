import { DeviceTypesTypescript } from '../../../typescript'
import * as types from '../../types'

export function allDeviceTypesRequest(deviceType: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.ALL_DEVICE_TYPES_REQUEST,
    payload: deviceType,
  }
}
export function allDeviceTypesSuccess(deviceTypes: DeviceTypesTypescript[]): {
  type: string
  payload: DeviceTypesTypescript[]
} {
  return {
    type: types.ALL_DEVICE_TYPES_SUCCESS,
    payload: deviceTypes,
  }
}
export function allDeviceTypesFailure(): { type: string } {
  return {
    type: types.ALL_DEVICE_TYPES_FAILURE,
  }
}

export function typesDeviceTypesRequest(deviceType: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.TYPES_DEVICE_TYPES_REQUEST,
    payload: deviceType,
  }
}
export function typesDeviceTypesSuccess(deviceTypes: DeviceTypesTypescript[]): {
  type: string
  payload: DeviceTypesTypescript[]
} {
  return {
    type: types.TYPES_DEVICE_TYPES_SUCCESS,
    payload: deviceTypes,
  }
}
export function typesDeviceTypesFailure(): { type: string } {
  return {
    type: types.TYPES_DEVICE_TYPES_FAILURE,
  }
}

export function oneDeviceTypeRequest(): {
  type: string
} {
  return {
    type: types.ONE_DEVICE_TYPE_REQUEST,
  }
}
export function oneDeviceTypeSuccess(deviceType: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.ONE_DEVICE_TYPE_SUCCESS,
    payload: deviceType,
  }
}
export function oneDeviceTypeFailure(): { type: string } {
  return {
    type: types.ONE_DEVICE_TYPE_FAILURE,
  }
}

export function addDeviceTypeRequest(deviceType: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.ADD_DEVICE_TYPE_REQUEST,
    payload: deviceType,
  }
}
export function addDeviceTypeSuccess(data: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.ADD_DEVICE_TYPE_SUCCESS,
    payload: data,
  }
}
export function addDeviceTypeFailure(): { type: string } {
  return {
    type: types.ADD_DEVICE_TYPE_FAILURE,
  }
}

export function updDeviceTypeRequest(device: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.UPD_DEVICE_TYPE_REQUEST,
    payload: device,
  }
}
export function updDeviceTypeSuccess(data: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.UPD_DEVICE_TYPE_SUCCESS,
    payload: data,
  }
}
export function updDeviceTypeFailure(data: DeviceTypesTypescript): {
  type: string
  payload: DeviceTypesTypescript
} {
  return {
    type: types.UPD_DEVICE_TYPE_FAILURE,
    payload: data,
  }
}
