import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import {
  ChangePasswordTypescript,
  LoginTypescript,
  RecoverySendEmailTypescript,
  UsersTypescript,
} from '../../../typescript'
import {
  changePasswordFailure,
  changePasswordSuccess,
  loginCreateTokenFailure,
  loginCreateTokenSuccess,
  loginFailure,
  loginSuccess,
  logoutSuccess,
  recoverySendEmailFailure,
  recoverySendEmailSuccess,
} from '../..'
import * as types from '../../types'
import { toast } from 'react-toastify'

const loginRequisicao = async (login: LoginTypescript) => {
  return new Promise((resolve, reject) => {
    return api({
      method: 'post',
      data: {
        query: `
        query {
          getPasswd(find:{
            email:"${login.email}"
            password:"${login.password}"}) {    
            idGroup
            token
            result
            message
          }
        }  
        `,
      },
    })
      .then(({ data }) => {
        resolve(data.data.getPasswd)
      })
      .catch(reject)
  })
}
let loginCreateTokenApi
const loginCreateTokenRequisicao = async (email: string) => {
  const mutation = `
    mutation createToken{
      createToken(
        email:"${email}"
        ){
        token
        result
      }
    }
    `
  await api({
    method: 'post',
    data: {
      query: mutation,
    },
  })
    .then(({ data }) => {
      loginCreateTokenApi = data.data.createToken
      return true
    })
    .catch((e) => e)
}

// Recovery Send Email
let recoverySendEmailApi: RecoverySendEmailTypescript
const recoverySendEmailRequisicao = async (data) => {
  const query = `
        mutation recoverPassword{
            recoverPassword(
                update:
                {
                    ${data.email ? 'email: "' + data.email + '"' : ''}
                }
            )
            {
            result
            message
            }
        }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    recoverySendEmailApi = data.data.recoverPassword
    return true
  })
}

// Change Password
let changePasswordApi: ChangePasswordTypescript
const changePasswordRequisicao = async (
  changePassword: ChangePasswordTypescript,
) => {
  const mutation = `
    mutation resetPassword{
      resetPassword(
        email:"${changePassword.email}"
        resetPasswordToken:"${changePassword.resetPasswordToken}"
        password:"${changePassword.password}"
        ){
        result
        message
        err
      }
    }
    `
  await api({
    method: 'post',
    data: {
      query: mutation,
    },
  })
    .then(({ data }) => {
      changePasswordApi = data.data.resetPassword
      return true
    })
    .catch((e) => e)
}

function* login(action) {
  try {
    const loginApi: UsersTypescript = yield call(
      loginRequisicao,
      action.payload,
    )
    yield put(loginApi.result ? loginSuccess(loginApi) : loginFailure(loginApi))
  } catch {
    toast.error(
      'Ocorreu um erro ao tentar acessar o servidor Global IoT, verifique sua conexão com a internet ou tente mais tarde',
    )
    yield put(loginFailure({}))
  }
}
function* loginCreateToken(action) {
  try {
    yield call(loginCreateTokenRequisicao, action.payload)
    yield put(loginCreateTokenSuccess(loginCreateTokenApi))
  } catch {
    yield put(loginCreateTokenFailure(action.payload))
  }
}

function* recoverySendEmail(action) {
  try {
    yield call(recoverySendEmailRequisicao, action.payload)
    yield put(recoverySendEmailSuccess(recoverySendEmailApi))
  } catch {
    yield put(recoverySendEmailFailure(action.payload))
  }
}
function* changePassword(action) {
  try {
    yield call(changePasswordRequisicao, action.payload)
    yield put(changePasswordSuccess(changePasswordApi))
  } catch {
    yield put(changePasswordFailure(changePasswordApi))
  }
}

function* logout() {
  try {
    yield localStorage.removeItem('global-iot/token')
    yield put(logoutSuccess())
  } catch {
    yield put(loginFailure({}))
  }
}

export default all([
  takeLatest(types.LOGIN_REQUEST, login),
  takeLatest(types.LOGIN_CREATE_TOKEN_REQUEST, loginCreateToken),
  takeLatest(types.LOGIN_RECOVERY_SEND_EMAIL_REQUEST, recoverySendEmail),
  takeLatest(types.LOGIN_CHANGE_PASSWORD_REQUEST, changePassword),
  takeLatest(types.LOGOUT_REQUEST, logout),
])
