import { createSelector } from 'reselect'
import {
  RecoverySendEmailTypescript,
  UsersTypescript,
} from '../../../typescript'

// Getters
const getUser = (state: { login: { user: UsersTypescript } }) =>
  state.login.user
const getCreateToken = (state: { login: { createToken: UsersTypescript } }) =>
  state.login.createToken
const getChangePassword = (state: {
  login: { changePassword: UsersTypescript }
}) => state.login.changePassword
const getRecoverySendEmail = (state: {
  login: { recoverySendEmail: RecoverySendEmailTypescript }
}) => state.login.recoverySendEmail

// Loadings
const getLoading = (state: { login: { loading: boolean } }) =>
  state.login.loading
const getLoadingCreateToken = (state: {
  login: { loadingCreateToken: boolean }
}) => state.login.loadingCreateToken
const getLoadingChangePassword = (state: {
  login: { loadingChangePassword: boolean }
}) => state.login.loadingChangePassword
const getLoadingRecoverySendEmail = (state: {
  login: { loadingRecoverySendEmail: boolean }
}) => state.login.loadingRecoverySendEmail

// exports
export const getUserLoginSelector = createSelector(
  getUser,
  (userLogged: UsersTypescript) => userLogged,
)
export const getCreateTokenSelector = createSelector(
  getCreateToken,
  (createToken: UsersTypescript) => createToken,
)
export const getChangePasswordSelector = createSelector(
  getChangePassword,
  (changePassword: UsersTypescript) => changePassword,
)
export const getRecoverySendEmailSelector = createSelector(
  getRecoverySendEmail,
  (recoverySendEmail: UsersTypescript) => recoverySendEmail,
)

// Exports Loadings
export const getLoadingUserLoginSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingCreateTokenSelector = createSelector(
  getLoadingCreateToken,
  (loadingCreateToken: boolean) => loadingCreateToken,
)
export const getLoadingChangePasswordSelector = createSelector(
  getLoadingChangePassword,
  (loadingChangePassword: boolean) => loadingChangePassword,
)
export const getLoadingRecoverySendEmailSelector = createSelector(
  getLoadingRecoverySendEmail,
  (loadingRecoverySendEmail: boolean) => loadingRecoverySendEmail,
)
