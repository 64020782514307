export const ONE_POSITION_REQUEST = 'ONE_POSITION_REQUEST'
export const ONE_POSITION_SUCCESS = 'ONE_POSITION_SUCCESS'
export const ONE_POSITION_FAILURE = 'ONE_POSITION_FAILURE'
export const ALL_POSITIONS_REQUEST = 'ALL_POSITIONS_REQUEST'
export const ALL_POSITIONS_SUCCESS = 'ALL_POSITIONS_SUCCESS'
export const ALL_POSITIONS_FAILURE = 'ALL_POSITIONS_FAILURE'
export const ADD_POSITION_REQUEST = 'ADD_POSITION_REQUEST'
export const ADD_POSITION_SUCCESS = 'ADD_POSITION_SUCCESS'
export const ADD_POSITION_FAILURE = 'ADD_POSITION_FAILURE'
export const DEL_POSITION_REQUEST = 'DEL_POSITION_REQUEST'
export const DEL_POSITION_SUCCESS = 'DEL_POSITION_SUCCESS'
export const DEL_POSITION_FAILURE = 'DEL_POSITION_FAILURE'
export const UPD_POSITION_REQUEST = 'UPD_POSITION_REQUEST'
export const UPD_POSITION_SUCCESS = 'UPD_POSITION_SUCCESS'
export const UPD_POSITION_FAILURE = 'UPD_POSITION_FAILURE'
