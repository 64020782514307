export const ONE_MENU_REQUEST = 'ONE_MENU_REQUEST'
export const ONE_MENU_SUCCESS = 'ONE_MENU_SUCCESS'
export const ONE_MENU_FAILURE = 'ONE_MENU_FAILURE'
export const ALL_MENUS_REQUEST = 'ALL_MENUS_REQUEST'
export const ALL_MENUS_SUCCESS = 'ALL_MENUS_SUCCESS'
export const ALL_MENUS_FAILURE = 'ALL_MENUS_FAILURE'
export const ADD_MENU_REQUEST = 'ADD_MENU_REQUEST'
export const ADD_MENU_SUCCESS = 'ADD_MENU_SUCCESS'
export const ADD_MENU_FAILURE = 'ADD_MENU_FAILURE'
export const DEL_MENU_REQUEST = 'DEL_MENU_REQUEST'
export const DEL_MENU_SUCCESS = 'DEL_MENU_SUCCESS'
export const DEL_MENU_FAILURE = 'DEL_MENU_FAILURE'
export const UPD_MENU_REQUEST = 'UPD_MENU_REQUEST'
export const UPD_MENU_SUCCESS = 'UPD_MENU_SUCCESS'
export const UPD_MENU_FAILURE = 'UPD_MENU_FAILURE'
