/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { EventTypesTypescript } from '../../../typescript'
import {
  addEventTypeFailure,
  addEventTypeSuccess,
  allEventTypesFailure,
  allEventTypesSuccess,
  updEventTypeFailure,
  updEventTypeSuccess,
} from '../..'
import * as types from '../../types'

let allEventTypesApi: EventTypesTypescript[]
const allEventTypesRequisicao = async (eventType: EventTypesTypescript) => {
  const query = `
        query {
          allEventsType(find: {
            ${
              eventType.idEconomicGroup
                ? 'idEconomicGroup: "' + eventType.idEconomicGroup + '"'
                : ''
            }
            ${
              eventType.branchNumber
                ? 'branchNumber: "' + eventType.branchNumber + '"'
                : ''
            }
            ${
              eventType.description
                ? 'description: "' + eventType.description + '"'
                : ''
            }
            ${eventType._id ? '_id: "' + eventType._id + '"' : ''}
          }) {
            _id
            idEconomicGroup
            description
            branchNumber
            deviceArea
            ruleOne
            conditionOne
            valueOne
            comparisonOne
            ruleTwo
            conditionTwo
            valueTwo
            comparisonTwo
            ruleThree
            conditionThree
            valueThree
            comparisonThree
            ruleFour
            conditionFour
            valueFour
            result
            message
            err
          }
        }
      `

  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allEventTypesApi = data.data.allEventsType
    return true
  })
}

let addEventTypeApi: EventTypesTypescript
const addEventTypeRequisicao = async (addEventType: EventTypesTypescript) => {
  const query = `
  mutation {
    createEventsType(input:{
      ${
        addEventType.idEconomicGroup
          ? 'idEconomicGroup: "' + addEventType.idEconomicGroup + '"'
          : ''
      }
      ${
        addEventType.branchNumber
          ? 'branchNumber: "' + addEventType.branchNumber + '"'
          : ''
      }
      ${
        addEventType.description
          ? 'description: "' + addEventType.description + '"'
          : ''
      }
      ${
        addEventType.deviceArea
          ? 'deviceArea: "' + addEventType.deviceArea + '"'
          : ''
      }
      ${addEventType.valueOne ? 'valueOne: ' + addEventType.valueOne : ''}
      ${addEventType.ruleOne ? 'ruleOne: ' + addEventType.ruleOne : ''}
      ${
        addEventType.conditionOne
          ? 'conditionOne: "' + addEventType.conditionOne + '"'
          : ''
      }
      ${
        addEventType.comparisonOne
          ? 'comparisonOne: ' + addEventType.comparisonOne
          : ''
      }
      ${addEventType.valueTwo ? 'valueTwo: ' + addEventType.valueTwo : ''}
      ${addEventType.ruleTwo ? 'ruleTwo: ' + addEventType.ruleTwo : ''}
      ${
        addEventType.conditionTwo
          ? 'conditionTwo: "' + addEventType.conditionTwo + '"'
          : ''
      }
      ${
        addEventType.comparisonTwo
          ? 'comparisonTwo: ' + addEventType.comparisonTwo
          : ''
      }
      ${addEventType.valueThree ? 'valueThree: ' + addEventType.valueThree : ''}
      ${addEventType.ruleThree ? 'ruleThree: ' + addEventType.ruleThree : ''}
      ${
        addEventType.conditionThree
          ? 'conditionThree: "' + addEventType.conditionThree + '"'
          : ''
      }
      ${
        addEventType.comparisonThree
          ? 'comparisonThree: ' + addEventType.comparisonThree
          : ''
      }
      ${addEventType.valueFour ? 'valueFour: ' + addEventType.valueFour : ''}
      ${addEventType.ruleFour ? 'ruleFour: ' + addEventType.ruleFour : ''}
      ${
        addEventType.conditionFour
          ? 'conditionFour: "' + addEventType.conditionFour + '"'
          : ''
      }
    })
    {
      result
      message
      err 
    }

}
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    addEventTypeApi = data.data.createEventsType
    return true
  })
}

let updEventTypeApi: EventTypesTypescript
const updEventTypeRequisicao = async (updEventType: EventTypesTypescript) => {
  const query = `
  mutation {
    updateEventsType (update:{
      _id: "${updEventType._id}"
      ${
        updEventType.idEconomicGroup
          ? 'idEconomicGroup: "' + updEventType.idEconomicGroup + '"'
          : ''
      }
      ${
        updEventType.branchNumber
          ? 'branchNumber: "' + updEventType.branchNumber + '"'
          : ''
      }
      ${
        updEventType.deviceId ? 'deviceId: "' + updEventType.deviceId + '"' : ''
      }
      ${
        updEventType.description
          ? 'description: "' + updEventType.description + '"'
          : ''
      }
      ${
        updEventType.deviceArea
          ? 'deviceArea: "' + updEventType.deviceArea + '"'
          : ''
      }
      ${updEventType.valueOne ? 'valueOne: ' + updEventType.valueOne : ''}
      ${updEventType.ruleOne ? 'ruleOne: ' + updEventType.ruleOne : ''}
      ${
        updEventType.conditionOne
          ? 'conditionOne: "' + updEventType.conditionOne + '"'
          : ''
      }
      ${
        updEventType.comparisonOne
          ? 'comparisonOne: ' + updEventType.comparisonOne
          : ''
      }
      ${updEventType.valueTwo ? 'valueTwo: ' + updEventType.valueTwo : ''}
      ${updEventType.ruleTwo ? 'ruleTwo: ' + updEventType.ruleTwo : ''}
      ${
        updEventType.conditionTwo
          ? 'conditionTwo: "' + updEventType.conditionTwo + '"'
          : ''
      }
      ${
        updEventType.comparisonTwo
          ? 'comparisonTwo: ' + updEventType.comparisonTwo
          : ''
      }
      ${updEventType.valueThree ? 'valueThree: ' + updEventType.valueThree : ''}
      ${updEventType.ruleThree ? 'ruleThree: ' + updEventType.ruleThree : ''}
      ${
        updEventType.conditionThree
          ? 'conditionThree: "' + updEventType.conditionThree + '"'
          : ''
      }
      ${
        updEventType.comparisonThree
          ? 'comparisonThree: ' + updEventType.comparisonThree
          : ''
      }
      ${updEventType.valueFour ? 'valueFour: ' + updEventType.valueFour : ''}
      ${updEventType.ruleFour ? 'ruleFour: ' + updEventType.ruleFour : ''}
      ${
        updEventType.conditionFour
          ? 'conditionFour: "' + updEventType.conditionFour + '"'
          : ''
      }
    })
    {
      result
      message
      err 
    }
  }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updEventTypeApi = data.data.updateEventsType
    return true
  })
}

function* allEventTypes(action) {
  try {
    yield call(allEventTypesRequisicao, action.payload)
    yield put(allEventTypesSuccess(allEventTypesApi))
  } catch {
    yield put(allEventTypesFailure())
  }
}
function* addEventType(action) {
  try {
    yield call(addEventTypeRequisicao, action.payload)
    yield put(addEventTypeSuccess(addEventTypeApi))
  } catch {
    yield put(addEventTypeFailure())
  }
}
function* updEventType(action) {
  try {
    yield call(updEventTypeRequisicao, action.payload)
    yield put(updEventTypeSuccess(updEventTypeApi))
  } catch {
    yield put(updEventTypeFailure())
  }
}
export default all([
  takeLatest(types.ALL_EVENT_TYPES_REQUEST, allEventTypes),
  takeLatest(types.ADD_EVENT_TYPE_REQUEST, addEventType),
  takeLatest(types.UPD_EVENT_TYPE_REQUEST, updEventType),
])
