import { PositionsTypescript } from '../../../typescript'
import * as types from '../../types'

const position = {}

export function onePositionRequest(): {
  type: string
  payload: PositionsTypescript
} {
  return {
    type: types.ONE_POSITION_REQUEST,
    payload: position,
  }
}
export function onePositionSuccess(): { type: string } {
  return {
    type: types.ONE_POSITION_SUCCESS,
  }
}
export function onePositionFailure(): { type: string } {
  return {
    type: types.ONE_POSITION_FAILURE,
  }
}

export function allPositionsRequest(): {
  type: string
  payload: PositionsTypescript
} {
  return {
    type: types.ALL_POSITIONS_REQUEST,
    payload: position,
  }
}
export function allPositionsSuccess(data: PositionsTypescript[]): {
  type: string
  payload: PositionsTypescript[]
} {
  return {
    type: types.ALL_POSITIONS_SUCCESS,
    payload: data,
  }
}
export function allPositionsFailure(): { type: string } {
  return {
    type: types.ALL_POSITIONS_FAILURE,
  }
}

export function addPositionRequest(data: PositionsTypescript): {
  type: string
  payload: PositionsTypescript
} {
  return {
    type: types.ADD_POSITION_REQUEST,
    payload: data,
  }
}
export function addPositionSuccess(): {
  type: string
} {
  return {
    type: types.ALL_POSITIONS_SUCCESS,
  }
}
export function addPositionsFailure(): { type: string } {
  return {
    type: types.ALL_POSITIONS_FAILURE,
  }
}
