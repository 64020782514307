export const ONE_DEVICE_REQUEST = 'ONE_DEVICE_REQUEST'
export const ONE_DEVICE_SUCCESS = 'ONE_DEVICE_SUCCESS'
export const ONE_DEVICE_FAILURE = 'ONE_DEVICE_FAILURE'
export const ALL_DEVICES_REQUEST = 'ALL_DEVICES_REQUEST'
export const ALL_DEVICES_SUCCESS = 'ALL_DEVICES_SUCCESS'
export const ALL_DEVICES_FAILURE = 'ALL_DEVICES_FAILURE'
export const ALL_DEVICES_BY_GROUP_REQUEST = 'ALL_DEVICES_BY_GROUP_REQUEST'
export const ALL_DEVICES_BY_GROUP_SUCCESS = 'ALL_DEVICES_BY_GROUP_SUCCESS'
export const ALL_DEVICES_BY_GROUP_FAILURE = 'ALL_DEVICES_BY_GROUP_FAILURE'
export const DASHBOARD_CARD_DEVICES_REQUEST = 'DASHBOARD_CARD_DEVICES_REQUEST'
export const DASHBOARD_CARD_DEVICES_SUCCESS = 'DASHBOARD_CARD_DEVICES_SUCCESS'
export const DASHBOARD_CARD_DEVICES_FAILURE = 'DASHBOARD_CARD_DEVICES_FAILURE'
export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST'
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS'
export const ADD_DEVICE_FAILURE = 'ADD_DEVICE_FAILURE'
export const DEL_DEVICE_REQUEST = 'DEL_DEVICE_REQUEST'
export const DEL_DEVICE_SUCCESS = 'DEL_DEVICE_SUCCESS'
export const DEL_DEVICE_FAILURE = 'DEL_DEVICE_FAILURE'
export const UPD_DEVICE_REQUEST = 'UPD_DEVICE_REQUEST'
export const UPD_DEVICE_SUCCESS = 'UPD_DEVICE_SUCCESS'
export const UPD_DEVICE_FAILURE = 'UPD_DEVICE_FAILURE'
export const UPD_GROUP_DEVICE_REQUEST = 'UPD_GROUP_DEVICE_REQUEST'
export const UPD_GROUP_DEVICE_SUCCESS = 'UPD_GROUP_DEVICE_SUCCESS'
export const UPD_GROUP_DEVICE_FAILURE = 'UPD_GROUP_DEVICE_FAILURE'
