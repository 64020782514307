import { EventTypesTypescript } from '../../../typescript'
import * as types from '../../types'

export function allEventTypesRequest(eventType: EventTypesTypescript): {
  type: string
  payload?: EventTypesTypescript
} {
  return {
    type: types.ALL_EVENT_TYPES_REQUEST,
    payload: eventType,
  }
}
export function allEventTypesSuccess(eventTypes: EventTypesTypescript[]): {
  type: string
  payload: EventTypesTypescript[]
} {
  return {
    type: types.ALL_EVENT_TYPES_SUCCESS,
    payload: eventTypes,
  }
}
export function allEventTypesFailure(): { type: string } {
  return {
    type: types.ALL_EVENT_TYPES_FAILURE,
  }
}

export function addEventTypeRequest(eventType: EventTypesTypescript): {
  type: string
  payload: EventTypesTypescript
} {
  return {
    type: types.ADD_EVENT_TYPE_REQUEST,
    payload: eventType,
  }
}
export function addEventTypeSuccess(data: EventTypesTypescript): {
  type: string
  payload: EventTypesTypescript
} {
  return {
    type: types.ADD_EVENT_TYPE_SUCCESS,
    payload: data,
  }
}
export function addEventTypeFailure(): { type: string } {
  return {
    type: types.ADD_EVENT_TYPE_FAILURE,
  }
}

export function updEventTypeRequest(eventType: EventTypesTypescript): {
  type: string
  payload: EventTypesTypescript
} {
  return {
    type: types.UPD_EVENT_TYPE_REQUEST,
    payload: eventType,
  }
}
export function updEventTypeSuccess(data: EventTypesTypescript): {
  type: string
  payload: EventTypesTypescript
} {
  return {
    type: types.UPD_EVENT_TYPE_SUCCESS,
    payload: data,
  }
}
export function updEventTypeFailure(): { type: string } {
  return {
    type: types.UPD_EVENT_TYPE_FAILURE,
  }
}
