export const ONE_USER_REQUEST = 'ONE_USER_REQUEST'
export const ONE_USER_SUCCESS = 'ONE_USER_SUCCESS'
export const ONE_USER_FAILURE = 'ONE_USER_FAILURE'
export const ONE_USER_LOGIN_REQUEST = 'ONE_USER_LOGIN_REQUEST'
export const ONE_USER_LOGIN_SUCCESS = 'ONE_USER_LOGIN_SUCCESS'
export const ONE_USER_LOGIN_FAILURE = 'ONE_USER_LOGIN_FAILURE'
export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAILURE = 'ALL_USERS_FAILURE'
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'
export const DEL_USER_REQUEST = 'DEL_USER_REQUEST'
export const DEL_USER_SUCCESS = 'DEL_USER_SUCCESS'
export const DEL_USER_FAILURE = 'DEL_USER_FAILURE'
export const UPD_USER_REQUEST = 'UPD_USER_REQUEST'
export const UPD_USER_SUCCESS = 'UPD_USER_SUCCESS'
export const UPD_USER_FAILURE = 'UPD_USER_FAILURE'
export const UPD_USER_GROUP_REQUEST = 'UPD_USER_GROUP_REQUEST'
export const UPD_USER_GROUP_SUCCESS = 'UPD_USER_GROUP_SUCCESS'
export const UPD_USER_GROUP_FAILURE = 'UPD_USER_GROUP_FAILURE'
