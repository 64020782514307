import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../../api'
import { SectionsTypescript } from '../../../typescript'
import * as SectionsActions from '../../actions/sections'
import * as types from '../../types'

let oneSectionApi: SectionsTypescript
const oneSectionRequisicao = async (section: SectionsTypescript) => {
  const query = `
    query {
      oneSection (_id: "${section._id}") {
        _id        
        idBranch
        idEconomicGroup
        sectionNumber
        description
        xCoordinate
        yCoordinate
        result
        message
        err
      }
    }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    oneSectionApi = data.data.oneSection
    return true
  })
}

let allSectionsApi: SectionsTypescript[]
const allSectionsRequisicao = async (param: SectionsTypescript) => {
  const query = `
    query {
      allSections (find: {
        ${param._id ? '_id: "' + param._id + '"' : ''}
        ${
          param.sectionNumber
            ? 'sectionNumber: "' + param.sectionNumber + '"'
            : ''
        }
        ${param.description ? 'description: "' + param.description + '"' : ''}
      }) {
        _id
        idBranch
        idEconomicGroup
        sectionNumber
        description
        xCoordinate
        yCoordinate
        result
        message
        err
      }
    }
  `
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    allSectionsApi = data.data.allSections
    return true
  })
}

let addSectionApi: SectionsTypescript
const addSectionRequisicao = async (section: SectionsTypescript) => {
  const query = `
  mutation {
    createSections(input:{
      description: "${section.description}"
      idBranch: "${section.idBranch}"
      xCoordinate: ${section.xCoordinate}
      yCoordinate: ${section.yCoordinate}
    })
      {
      result
      message
      err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  })
    .then(({ data }) => {
      addSectionApi = data.data.createSections
      return true
    })
    .catch((e) => e)
}

let updSectionApi
const updSectionRequisicao = async (section: SectionsTypescript) => {
  const query = `
  mutation {
    updateSections(update:{
      _id: "${section._id}"
      description: "${section.description}"
     xCoordinate: ${section.xCoordinate}
      yCoordinate: ${section.yCoordinate}
    })
      {
        result
        message
        err
    }
  }
`
  await api({
    method: 'post',
    data: {
      query,
    },
  }).then(({ data }) => {
    updSectionApi = data.data.updateSections

    return true
  })
}

function* oneSection(action) {
  try {
    yield call(oneSectionRequisicao, action.payload)
    yield put(SectionsActions.oneSectionSuccess(oneSectionApi))
  } catch {
    yield put(SectionsActions.oneSectionFailure())
  }
}

function* allSections(action) {
  try {
    yield call(allSectionsRequisicao, action.payload)
    yield put(SectionsActions.allSectionsSuccess(allSectionsApi))
  } catch {
    yield put(SectionsActions.allSectionsFailure())
  }
}

function* addSection(action) {
  try {
    yield call(addSectionRequisicao, action.payload)
    yield put(SectionsActions.addSectionSuccess(addSectionApi))
  } catch {
    yield put(SectionsActions.addSectionFailure())
  }
}

function* updSection(action) {
  try {
    yield call(updSectionRequisicao, action.payload)
    yield put(SectionsActions.updSectionSuccess(updSectionApi))
  } catch {
    yield put(SectionsActions.updSectionFailure(updSectionApi))
  }
}

export default all([
  takeLatest(types.ALL_SECTIONS_REQUEST, allSections),
  takeLatest(types.ONE_SECTION_REQUEST, oneSection),
  takeLatest(types.ADD_SECTION_REQUEST, addSection),
  takeLatest(types.UPD_SECTION_REQUEST, updSection),
])
