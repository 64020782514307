export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_CHANGE_PASSWORD_REQUEST = 'LOGIN_CHANGE_PASSWORD_REQUEST'
export const LOGIN_CHANGE_PASSWORD_SUCCESS = 'LOGIN_CHANGE_PASSWORD_SUCCESS'
export const LOGIN_CHANGE_PASSWORD_FAILURE = 'LOGIN_CHANGE_PASSWORD_FAILURE'
export const LOGIN_CREATE_TOKEN_REQUEST = 'LOGIN_CREATE_TOKEN_REQUEST'
export const LOGIN_CREATE_TOKEN_RESET = 'LOGIN_CREATE_TOKEN_RESET'
export const LOGIN_CREATE_TOKEN_SUCCESS = 'LOGIN_CREATE_TOKEN_SUCCESS'
export const LOGIN_CREATE_TOKEN_FAILURE = 'LOGIN_CREATE_TOKEN_FAILURE'
export const LOGIN_CHANGE_PASSWORD_RESET = 'LOGIN_CHANGE_PASSWORD_RESET'
export const LOGIN_RECOVERY_SEND_EMAIL_REQUEST =
  'LOGIN_RECOVERY_SEND_EMAIL_REQUEST'
export const LOGIN_RECOVERY_SEND_EMAIL_SUCCESS =
  'LOGIN_RECOVERY_SEND_EMAIL_SUCCESS'
export const LOGIN_RECOVERY_SEND_EMAIL_FAILURE =
  'LOGIN_RECOVERY_SEND_EMAIL_FAILURE'
export const LOGIN_RECOVERY_SEND_EMAIL_RESET = 'LOGIN_RECOVERY_SEND_EMAIL_RESET'
