import { createSelector } from 'reselect'
import { ProfilesTypescript } from '../../../typescript'

// Getters
const getProfiles = (state: { profiles: { profiles: ProfilesTypescript[] } }) =>
  state.profiles.profiles
const addProfile = (state: { profiles: { addProfile: ProfilesTypescript } }) =>
  state.profiles.addProfile
const updProfile = (state: { profiles: { updProfile: ProfilesTypescript } }) =>
  state.profiles.updProfile
const getProfile = (state: { profiles: { profile: ProfilesTypescript } }) =>
  state.profiles.profile

// Loadings
const getLoading = (state: { profiles: { loading: boolean } }) =>
  state.profiles.loading
const addLoading = (state: { profiles: { loadingAdd: boolean } }) =>
  state.profiles.loadingAdd
const updLoading = (state: { profiles: { loadingUpd: boolean } }) =>
  state.profiles.loadingUpd
const getOneLoading = (state: { profiles: { loadingOne: boolean } }) =>
  state.profiles.loadingOne

// exports
export const getProfilesSelector = createSelector(
  getProfiles,
  getLoading,
  (profiles: ProfilesTypescript[]) => profiles,
)
export const getProfileSelector = createSelector(
  getProfile,
  getOneLoading,
  (profile: ProfilesTypescript) => profile,
)
export const addProfileSelector = createSelector(
  addProfile,
  addLoading,
  (addProfile: ProfilesTypescript) => addProfile,
)
export const updProfileSelector = createSelector(
  updProfile,
  updLoading,
  (updProfile: ProfilesTypescript) => updProfile,
)

// Exports Loadings
export const getLoadingProfilesSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingProfileSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddProfileSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdProfileSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
