export const ONE_TEMPERATURE_REQUEST = 'ONE_TEMPERATURE_REQUEST'
export const ONE_TEMPERATURE_SUCCESS = 'ONE_TEMPERATURE_SUCCESS'
export const ONE_TEMPERATURE_FAILURE = 'ONE_TEMPERATURE_FAILURE'
export const ALL_TEMPERATURES_REQUEST = 'ALL_TEMPERATURES_REQUEST'
export const ALL_TEMPERATURES_SUCCESS = 'ALL_TEMPERATURES_SUCCESS'
export const ALL_TEMPERATURES_FAILURE = 'ALL_TEMPERATURES_FAILURE'
export const ADD_TEMPERATURE_REQUEST = 'ADD_TEMPERATURE_REQUEST'
export const ADD_TEMPERATURE_SUCCESS = 'ADD_TEMPERATURE_SUCCESS'
export const ADD_TEMPERATURE_FAILURE = 'ADD_TEMPERATURE_FAILURE'
export const DEL_TEMPERATURE_REQUEST = 'DEL_TEMPERATURE_REQUEST'
export const DEL_TEMPERATURE_SUCCESS = 'DEL_TEMPERATURE_SUCCESS'
export const DEL_TEMPERATURE_FAILURE = 'DEL_TEMPERATURE_FAILURE'
export const UPD_TEMPERATURE_REQUEST = 'UPD_TEMPERATURE_REQUEST'
export const UPD_TEMPERATURE_SUCCESS = 'UPD_TEMPERATURE_SUCCESS'
export const UPD_TEMPERATURE_FAILURE = 'UPD_TEMPERATURE_FAILURE'
