export const ONE_WEATHER_REQUEST = 'ONE_WEATHER_REQUEST'
export const ONE_WEATHER_SUCCESS = 'ONE_WEATHER_SUCCESS'
export const ONE_WEATHER_FAILURE = 'ONE_WEATHER_FAILURE'
export const ALL_WEATHERS_REQUEST = 'ALL_WEATHERS_REQUEST'
export const ALL_WEATHERS_SUCCESS = 'ALL_WEATHERS_SUCCESS'
export const ALL_WEATHERS_FAILURE = 'ALL_WEATHERS_FAILURE'
export const ADD_WEATHER_REQUEST = 'ADD_WEATHER_REQUEST'
export const ADD_WEATHER_SUCCESS = 'ADD_WEATHER_SUCCESS'
export const ADD_WEATHER_FAILURE = 'ADD_WEATHER_FAILURE'
export const DEL_WEATHER_REQUEST = 'DEL_WEATHER_REQUEST'
export const DEL_WEATHER_SUCCESS = 'DEL_WEATHER_SUCCESS'
export const DEL_WEATHER_FAILURE = 'DEL_WEATHER_FAILURE'
export const UPD_WEATHER_REQUEST = 'UPD_WEATHER_REQUEST'
export const UPD_WEATHER_SUCCESS = 'UPD_WEATHER_SUCCESS'
export const UPD_WEATHER_FAILURE = 'UPD_WEATHER_FAILURE'
