/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ChangePasswordTypescript,
  LoginTypescript,
  RecoverySendEmailTypescript,
  UsersTypescript,
} from '../../../typescript'
import * as types from '../../types'

export interface LoginState {
  logged: boolean
  loading: boolean
  loadingToken: boolean
  loadingChangePassword: boolean
  loadingRecoverySendEmail: boolean
  user: UsersTypescript
  login: LoginTypescript
  error: string | null
  changePassword: ChangePasswordTypescript
  recoverySendEmail: RecoverySendEmailTypescript
  createToken: { email?: string; result?: boolean }
}

const initialState: LoginState = {
  loading: false,
  loadingToken: false,
  loadingChangePassword: false,
  loadingRecoverySendEmail: false,
  createToken: { email: '', result: false },
  logged: false,
  user: {
    _id: '',
    idGroup: '',
    email: '',
    userName: '',
    password: '',
    profiles: '',
    associatedBranches: '',
    token: '',
    admin: false,
    result: false,
    message: '',
    err: '',
  },
  login: {
    email: '',
    password: '',
    result: false,
    message: '',
  },
  changePassword: {
    email: '',
    password: '',
    resetPasswordToken: '',
    result: false,
    message: '',
    err: '',
  },
  recoverySendEmail: {
    email: '',
    result: false,
    message: '',
    err: '',
  },
  error: null,
}

const loginReducer = (
  state = initialState,
  action: {
    type: string
    payload: UsersTypescript
  },
) => {
  switch (action.type) {
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.LOGOUT_SUCCESS:
      return {
        ...initialState,
        user: {
          _id: '',
          idGroup: '',
          email: '',
          userName: '',
          password: '',
          profiles: '',
          associatedBranches: '',
          token: '',
        },
        loading: false,
      }

    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.LOGIN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { token } = action.payload
      return {
        ...state,
        user: { ...action.payload, token },
        loading: false,
        logged: true,
      }

    case types.LOGIN_FAILURE:
      return { ...state, user: { ...action.payload }, loading: false }

    case types.LOGIN_CREATE_TOKEN_REQUEST:
      return {
        ...state,
        createToken: { email: action.payload },
        loadingToken: true,
        logged: true,
      }

    case types.LOGIN_CREATE_TOKEN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      if (action.payload.token) {
        localStorage.removeItem('global-iot/token')
        localStorage.setItem('global-iot/token', action.payload.token)
      }

      return {
        ...state,
        logged: true,
        createToken: action.payload,
      }
    case types.LOGIN_CREATE_TOKEN_RESET:
      return {
        ...state,
        createToken: { email: '', result: false },
      }

    case types.LOGIN_CREATE_TOKEN_FAILURE:
      return {
        ...state,
        user: { ...action.payload },
        loadingToken: false,
        logged: false,
      }

    case types.LOGIN_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: action.payload,
        loadingChangePassword: true,
      }

    case types.LOGIN_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: action.payload,
        loadingChangePassword: false,
      }

    case types.LOGIN_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: action.payload,
        loadingChangePassword: false,
      }

    case types.LOGIN_CHANGE_PASSWORD_RESET:
      return {
        ...state,
        changePassword: {
          email: '',
          password: '',
          resetPasswordToken: '',
          result: false,
          message: '',
          err: '',
        },
        loadingChangePassword: false,
      }

    case types.LOGIN_RECOVERY_SEND_EMAIL_REQUEST:
      return {
        ...state,
        recoverySendEmail: action.payload,
        loadingRecoverySendEmail: true,
      }

    case types.LOGIN_RECOVERY_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        recoverySendEmail: action.payload,
        loadingRecoverySendEmail: false,
      }

    case types.LOGIN_RECOVERY_SEND_EMAIL_FAILURE:
      return {
        ...state,
        recoverySendEmail: action.payload,
        loadingRecoverySendEmail: false,
      }

    case types.LOGIN_RECOVERY_SEND_EMAIL_RESET:
      return {
        ...state,
        recoverySendEmail: {
          email: '',
          password: '',
          resetPasswordToken: '',
          result: false,
          message: '',
          err: '',
        },
        loadingRecoverySendEmail: false,
      }

    default:
      return { ...state }
  }
}
export default loginReducer
