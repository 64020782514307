import { createSelector } from 'reselect'
import { MenusTypescript } from '../../../typescript'

// Getters
const getMenus = (state: { menus: { menus: MenusTypescript[] } }) =>
  state.menus.menus
const addMenu = (state: { menus: { addMenu: MenusTypescript } }) =>
  state.menus.addMenu
const updMenu = (state: { menus: { updMenu: MenusTypescript } }) =>
  state.menus.updMenu
const getMenu = (state: { menus: { menu: MenusTypescript } }) =>
  state.menus.menu

// Loadings
const getLoading = (state: { menus: { loading: boolean } }) =>
  state.menus.loading
const addLoading = (state: { menus: { loadingAdd: boolean } }) =>
  state.menus.loadingAdd
const updLoading = (state: { menus: { loadingUpd: boolean } }) =>
  state.menus.loadingUpd
const getOneLoading = (state: { menus: { loadingOne: boolean } }) =>
  state.menus.loadingOne

// exports
export const getMenusSelector = createSelector(
  getMenus,
  getLoading,
  (menus: MenusTypescript[]) => menus,
)
export const getMenuSelector = createSelector(
  getMenu,
  getOneLoading,
  (menu: MenusTypescript) => menu,
)
export const addMenuSelector = createSelector(
  addMenu,
  addLoading,
  (addMenu: MenusTypescript) => addMenu,
)
export const updMenuSelector = createSelector(
  updMenu,
  updLoading,
  (updMenu: MenusTypescript) => updMenu,
)

// Exports Loadings
export const getLoadingMenusSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingMenuSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddMenuSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdMenuSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
