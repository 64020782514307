export const ONE_RESELLER_REQUEST = 'ONE_RESELLER_REQUEST'
export const ONE_RESELLER_SUCCESS = 'ONE_RESELLER_SUCCESS'
export const ONE_RESELLER_FAILURE = 'ONE_RESELLER_FAILURE'
export const ALL_RESELLERS_REQUEST = 'ALL_RESELLERS_REQUEST'
export const ALL_RESELLERS_SUCCESS = 'ALL_RESELLERS_SUCCESS'
export const ALL_RESELLERS_FAILURE = 'ALL_RESELLERS_FAILURE'
export const ALLOWED_RESELLERS_REQUEST = 'ALLOWED_RESELLERS_REQUEST'
export const ALLOWED_RESELLERS_SUCCESS = 'ALLOWED_RESELLERS_SUCCESS'
export const ALLOWED_RESELLERS_FAILURE = 'ALLOWED_RESELLERS_FAILURE'
export const ADD_RESELLER_REQUEST = 'ADD_RESELLER_REQUEST'
export const ADD_RESELLER_SUCCESS = 'ADD_RESELLER_SUCCESS'
export const ADD_RESELLER_FAILURE = 'ADD_RESELLER_FAILURE'
export const DEL_RESELLER_REQUEST = 'DEL_RESELLER_REQUEST'
export const DEL_RESELLER_SUCCESS = 'DEL_RESELLER_SUCCESS'
export const DEL_RESELLER_FAILURE = 'DEL_RESELLER_FAILURE'
export const UPD_RESELLER_REQUEST = 'UPD_RESELLER_REQUEST'
export const UPD_RESELLER_SUCCESS = 'UPD_RESELLER_SUCCESS'
export const UPD_RESELLER_FAILURE = 'UPD_RESELLER_FAILURE'
