import { applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'
import PersistStore from './PersistStore'

const sagaMiddleware = createSagaMiddleware()
const store = new PersistStore(applyMiddleware(sagaMiddleware)).store

sagaMiddleware.run(rootSaga)

export default store
export * from './actions'
export * from './selectors'
