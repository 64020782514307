export const ONE_SECURITY_POSITION_REQUEST = 'ONE_SECURITY_POSITION_REQUEST'
export const ONE_SECURITY_POSITION_SUCCESS = 'ONE_SECURITY_POSITION_SUCCESS'
export const ONE_SECURITY_POSITION_FAILURE = 'ONE_SECURITY_POSITION_FAILURE'
export const ALL_SECURITY_POSITIONS_REQUEST = 'ALL_SECURITY_POSITIONS_REQUEST'
export const ALL_SECURITY_POSITIONS_SUCCESS = 'ALL_SECURITY_POSITIONS_SUCCESS'
export const ALL_SECURITY_POSITIONS_FAILURE = 'ALL_SECURITY_POSITIONS_FAILURE'
export const ADD_SECURITY_POSITION_REQUEST = 'ADD_SECURITY_POSITION_REQUEST'
export const ADD_SECURITY_POSITION_SUCCESS = 'ADD_SECURITY_POSITION_SUCCESS'
export const ADD_SECURITY_POSITION_FAILURE = 'ADD_SECURITY_POSITION_FAILURE'
export const DEL_SECURITY_POSITION_REQUEST = 'DEL_SECURITY_POSITION_REQUEST'
export const DEL_SECURITY_POSITION_SUCCESS = 'DEL_SECURITY_POSITION_SUCCESS'
export const DEL_SECURITY_POSITION_FAILURE = 'DEL_SECURITY_POSITION_FAILURE'
export const UPD_SECURITY_POSITION_REQUEST = 'UPD_SECURITY_POSITION_REQUEST'
export const UPD_SECURITY_POSITION_SUCCESS = 'UPD_SECURITY_POSITION_SUCCESS'
export const UPD_SECURITY_POSITION_FAILURE = 'UPD_SECURITY_POSITION_FAILURE'
