import React from 'react'
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import 'core-js'
import './polyfill'
// import * as React from 'react';
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet'

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './redux'

import { isMobile } from 'react-device-detect'

declare module 'react' {
  let icons: { [key: string]: string | Array<string> }
}

React.icons = icons

const Render = () => {
  if (isMobile) {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-center px-3"
          style={{ height: '100vh' }}
        >
          <img
            src={'logos/GIOT_03.png'}
            className="logo-giot mt-2"
            alt="Esta imagem com logo da empresa"
          />
          <a
            className="btn btn-success d-block p-2 mt-2"
            href="https://play.google.com/store/apps/details?id=com.globaliot"
          >
            Baixar
          </a>
          <h6 className="p-2 text-center">
            Para acesso via 'Mobile' utilize nosso aplicativo.
          </h6>
        </div>
      </>
    )
  }
  return (
    <Provider store={store}>
      <App />
      <ToastContainer autoClose={2000} />
    </Provider>
  )
}

ReactDOM.render(<Render />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
