import {
  DashboardWeatherTypescript,
  LastWeatherReadTypescript,
  WeatherTypescript,
} from '../../../typescript'
import * as types from '../../types'

const weather = {}

export function oneWeatherRequest(): {
  type: string
  payload
} {
  return {
    type: types.ONE_WEATHER_REQUEST,
    payload: weather,
  }
}
export function oneWeatherSuccess(): { type: string } {
  return {
    type: types.ONE_WEATHER_SUCCESS,
  }
}
export function oneWeatherFailure(): { type: string } {
  return {
    type: types.ONE_WEATHER_FAILURE,
  }
}

export function oneDashboardWeatherRequest(serialNumber): {
  type: string
  payload: WeatherTypescript
} {
  return {
    type: types.ONE_DASHBOARD_WEATHER_REQUEST,
    payload: serialNumber,
  }
}
export function oneDashboardWeatherSuccess(
  data: DashboardWeatherTypescript[],
): {
  type: string
  payload: DashboardWeatherTypescript[]
} {
  return {
    type: types.ONE_DASHBOARD_WEATHER_SUCCESS,
    payload: data,
  }
}
export function oneDashboardWeatherFailure(): { type: string } {
  return {
    type: types.ONE_DASHBOARD_WEATHER_FAILURE,
  }
}

export function allWeathersRequest(serialNumber: string): {
  type: string
  payload: string
} {
  return {
    type: types.ALL_WEATHERS_REQUEST,
    payload: serialNumber,
  }
}

export function allWeathersSuccess(data: DashboardWeatherTypescript[]): {
  type: string
  payload: DashboardWeatherTypescript[]
} {
  return {
    type: types.ALL_WEATHERS_SUCCESS,
    payload: data,
  }
}
export function allWeathersFailure(): { type: string } {
  return {
    type: types.ALL_WEATHERS_FAILURE,
  }
}

export function lastWeatherReadRequest(dashboardWeather): {
  type: string
  payload: DashboardWeatherTypescript
} {
  return {
    type: types.LAST_WEATHERS_READ_REQUEST,
    payload: dashboardWeather,
  }
}
export function lastWeatherReadSuccess(data: LastWeatherReadTypescript[]): {
  type: string
  payload: LastWeatherReadTypescript[]
} {
  return {
    type: types.LAST_WEATHERS_READ_SUCCESS,
    payload: data,
  }
}
export function lastWeatherReadFailure(): { type: string } {
  return {
    type: types.LAST_WEATHERS_READ_FAILURE,
  }
}

export function lastWeatherReadGridRequest(params): {
  type: string
  payload: LastWeatherReadTypescript
} {
  return {
    type: types.LAST_WEATHERS_READ_GRID_REQUEST,
    payload: params,
  }
}
export function lastWeatherReadGridSuccess(data: LastWeatherReadTypescript[]): {
  type: string
  payload: LastWeatherReadTypescript[]
} {
  return {
    type: types.LAST_WEATHERS_READ_GRID_SUCCESS,
    payload: data,
  }
}
export function lastWeatherReadGridFailure(): { type: string } {
  return {
    type: types.LAST_WEATHERS_READ_GRID_FAILURE,
  }
}

export function addWeatherRequest(data: WeatherTypescript): {
  type: string
  payload: WeatherTypescript
} {
  return {
    type: types.ADD_WEATHER_REQUEST,
    payload: data,
  }
}
export function addWeatherSuccess(): {
  type: string
} {
  return {
    type: types.ADD_WEATHER_SUCCESS,
  }
}
export function addWeathersFailure(): { type: string } {
  return {
    type: types.ADD_WEATHER_FAILURE,
  }
}
