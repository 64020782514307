export const ONE_COMMAND_REQUEST = 'ONE_COMMAND_REQUEST'
export const ONE_COMMAND_SUCCESS = 'ONE_COMMAND_SUCCESS'
export const ONE_COMMAND_FAILURE = 'ONE_COMMAND_FAILURE'
export const ALL_COMMANDS_REQUEST = 'ALL_COMMANDS_REQUEST'
export const ALL_COMMANDS_SUCCESS = 'ALL_COMMANDS_SUCCESS'
export const ALL_COMMANDS_FAILURE = 'ALL_COMMANDS_FAILURE'
export const ADD_COMMAND_REQUEST = 'ADD_COMMAND_REQUEST'
export const ADD_COMMAND_SUCCESS = 'ADD_COMMAND_SUCCESS'
export const ADD_COMMAND_FAILURE = 'ADD_COMMAND_FAILURE'
export const DEL_COMMAND_REQUEST = 'DEL_COMMAND_REQUEST'
export const DEL_COMMAND_SUCCESS = 'DEL_COMMAND_SUCCESS'
export const DEL_COMMAND_FAILURE = 'DEL_COMMAND_FAILURE'
export const UPD_COMMAND_REQUEST = 'UPD_COMMAND_REQUEST'
export const UPD_COMMAND_SUCCESS = 'UPD_COMMAND_SUCCESS'
export const UPD_COMMAND_FAILURE = 'UPD_COMMAND_FAILURE'
