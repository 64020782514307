export const ONE_ECONOMIC_GROUP_REQUEST = 'ONE_ECONOMIC_GROUP_REQUEST'
export const ONE_ECONOMIC_GROUP_SUCCESS = 'ONE_ECONOMIC_GROUP_SUCCESS'
export const ONE_ECONOMIC_GROUP_FAILURE = 'ONE_ECONOMIC_GROUP_FAILURE'
export const ACTUAL_ECONOMIC_GROUP_REQUEST = 'ACTUAL_ECONOMIC_GROUP_REQUEST'
export const ACTUAL_ECONOMIC_GROUP_RESET = 'ACTUAL_ECONOMIC_GROUP_RESET'
export const ACTUAL_ECONOMIC_GROUP_SUCCESS = 'ACTUAL_ECONOMIC_GROUP_SUCCESS'
export const ACTUAL_ECONOMIC_GROUP_FAILURE = 'ACTUAL_ECONOMIC_GROUP_FAILURE'
export const ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST =
  'ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST'
export const ALL_ECONOMIC_GROUPS_AUTHORIZED_SUCCESS =
  'ALL_ECONOMIC_GROUPS_AUTHORIZED_SUCCESS'
export const ALL_ECONOMIC_GROUPS_AUTHORIZED_FAILURE =
  'ALL_ECONOMIC_GROUPS_AUTHORIZED_FAILURE'
export const ALL_ECONOMIC_GROUPS_REQUEST = 'ALL_ECONOMIC_GROUPS_REQUEST'
export const ALL_ECONOMIC_GROUPS_SUCCESS = 'ALL_ECONOMIC_GROUPS_SUCCESS'
export const ALL_ECONOMIC_GROUPS_FAILURE = 'ALL_ECONOMIC_GROUPS_FAILURE'
export const ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST =
  'ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST'
export const ALL_ECONOMIC_GROUPS_TO_RESSELERS_SUCCESS =
  'ALL_ECONOMIC_GROUPS_TO_RESSELERS_SUCCESS'
export const ALL_ECONOMIC_GROUPS_TO_RESSELERS_FAILURE =
  'ALL_ECONOMIC_GROUPS_TO_RESSELERS_FAILURE'
export const ADD_ECONOMIC_GROUP_REQUEST = 'ADD_ECONOMIC_GROUP_REQUEST'
export const ADD_ECONOMIC_GROUP_SUCCESS = 'ADD_ECONOMIC_GROUP_SUCCESS'
export const ADD_ECONOMIC_GROUP_FAILURE = 'ADD_ECONOMIC_GROUP_FAILURE'
export const DEL_ECONOMIC_GROUP_REQUEST = 'DEL_ECONOMIC_GROUP_REQUEST'
export const DEL_ECONOMIC_GROUP_SUCCESS = 'DEL_ECONOMIC_GROUP_SUCCESS'
export const DEL_ECONOMIC_GROUP_FAILURE = 'DEL_ECONOMIC_GROUP_FAILURE'
export const UPD_ECONOMIC_GROUP_REQUEST = 'UPD_ECONOMIC_GROUP_REQUEST'
export const UPD_ECONOMIC_GROUP_SUCCESS = 'UPD_ECONOMIC_GROUP_SUCCESS'
export const UPD_ECONOMIC_GROUP_FAILURE = 'UPD_ECONOMIC_GROUP_FAILURE'
export const EDT_ECONOMIC_GROUP_REQUEST = 'EDT_ECONOMIC_GROUP_REQUEST'
