export const ALL_REPORT_TEMPERATURE_DETAILS_REQUEST =
  'ALL_REPORT_TEMPERATURE_DETAILS_REQUEST'
export const ALL_REPORT_TEMPERATURE_DETAILS_SUCCESS =
  'ALL_REPORT_TEMPERATURE_DETAILS_SUCCESS'
export const ALL_REPORT_TEMPERATURE_DETAILS_FAILURE =
  'ALL_REPORT_TEMPERATURE_DETAILS_FAILURE'
export const SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST =
  'SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_REQUEST'
export const SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_SUCCESS =
  'SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_SUCCESS'
export const SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_FAILURE =
  'SYNTHETIC_REPORT_TEMPERATURE_BY_DATE_FAILURE'
