/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EconomicGroupsTypescript } from '../../../typescript'
import * as types from '../../types'

export interface EconomicGroupState {
  loading: boolean
  loadingActual: boolean
  loadingToResellers: boolean
  loadingFiltered: boolean
  loadingOne: boolean
  loadingAdd: boolean
  loadingUpd: boolean
  param: EconomicGroupsTypescript
  economicGroup: EconomicGroupsTypescript
  actualEconomicGroup: EconomicGroupsTypescript
  economicGroupAuthorized: EconomicGroupsTypescript[]
  addEconomicGroup: EconomicGroupsTypescript
  updEconomicGroup: EconomicGroupsTypescript
  economicGroups: EconomicGroupsTypescript[]
  economicGroupsToResellers: EconomicGroupsTypescript[]
  economicGroupsFiltered: EconomicGroupsTypescript[]
  error: string | null
  editingId: string
}

const initialState: EconomicGroupState = {
  loading: false,
  loadingActual: false,
  loadingToResellers: false,
  loadingFiltered: false,
  loadingOne: false,
  loadingAdd: false,
  loadingUpd: false,
  param: {
    groupNumber: 0,
    _id: '',
    groupName: '',
    tradingName: '',
    identificationDocument: '',
  },
  addEconomicGroup: {
    _id: '', // gerados automaticamente no back
    groupNumber: 0, // gerados automaticamente no back
    err: '', // gerados automaticamente no back
    result: false, // gerados automaticamente no back
    message: '', // gerados automaticamente no back
    active: false,
    groupName: '',
    tradingName: '',
    identificationDocument: '',
    observations: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    notificationsEmails: '',
  },
  updEconomicGroup: {
    _id: '', // gerados automaticamente no back
    groupNumber: 0, // gerados automaticamente no back
    err: '', // gerados automaticamente no back
    result: false, // gerados automaticamente no back
    message: '', // gerados automaticamente no back
    active: false,
    groupName: '',
    tradingName: '',
    identificationDocument: '',
    observations: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    notificationsEmails: '',
  },
  economicGroup: {
    _id: '', // gerados automaticamente no back
    groupNumber: 0, // gerados automaticamente no back
    err: '', // gerados automaticamente no back
    result: false, // gerados automaticamente no back
    message: '', // gerados automaticamente no back
    active: false,
    groupName: '',
    tradingName: '',
    identificationDocument: '',
    observations: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    notificationsEmails: '',
  },
  actualEconomicGroup: {
    _id: '', // gerados automaticamente no back
    groupNumber: 0, // gerados automaticamente no back
    err: '', // gerados automaticamente no back
    result: false, // gerados automaticamente no back
    message: '', // gerados automaticamente no back
    active: false,
    groupName: '',
    tradingName: '',
    identificationDocument: '',
    observations: '',
    phoneNumber: '',
    email: '',
    responsible: '',
    notificationsEmails: '',
  },
  economicGroupAuthorized: [],
  economicGroups: [],
  economicGroupsToResellers: [],
  economicGroupsFiltered: [],
  editingId: '',
  error: null,
}

const economicGroupReducer = (
  state = initialState,
  action: {
    type: string
    payload: EconomicGroupsTypescript[] | EconomicGroupsTypescript
  },
) => {
  switch (action.type) {
    case types.ONE_ECONOMIC_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        economicGroup: action.payload,
        addEconomicGroup: { ...action.payload, result: false, message: '' },
        updEconomicGroup: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }
    case types.ONE_ECONOMIC_GROUP_SUCCESS:
      return { ...state, economicGroup: action.payload, loadingOne: false }
    case types.ONE_ECONOMIC_GROUP_FAILURE:
      return { ...state, loadingOne: false }

    case types.ACTUAL_ECONOMIC_GROUP_REQUEST:
      return {
        ...state,
        loadingActual: true,
        actualEconomicGroup: action.payload,
        addEconomicGroup: { ...action.payload, result: false, message: '' },
        updEconomicGroup: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }
    case types.ACTUAL_ECONOMIC_GROUP_SUCCESS:
      return {
        ...state,
        actualEconomicGroup: action.payload,
        loadingActual: false,
      }

    case types.ACTUAL_ECONOMIC_GROUP_RESET:
      return {
        ...initialState,
        actualEconomicGroup: {
          _id: '', // gerados automaticamente no back
          groupNumber: 0, // gerados automaticamente no back
          err: '', // gerados automaticamente no back
          result: false, // gerados automaticamente no back
          message: '', // gerados automaticamente no back
          active: false,
          groupName: '',
          tradingName: '',
          identificationDocument: '',
          observations: '',
          phoneNumber: '',
          email: '',
          responsible: '',
          notificationsEmails: '',
        },
        loadingActual: false,
      }
    case types.ACTUAL_ECONOMIC_GROUP_FAILURE:
      return state

    case types.ALL_ECONOMIC_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
        param: action.payload,
        addEconomicGroup: { ...action.payload, result: false, message: '' },
        updEconomicGroup: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_ECONOMIC_GROUPS_SUCCESS:
      return { ...state, economicGroups: action.payload, loading: false }

    case types.ALL_ECONOMIC_GROUPS_FAILURE:
      return state

    case types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_REQUEST:
      return {
        ...state,
        loadingToResellers: true,
        param: action.payload,
        addEconomicGroup: { ...action.payload, result: false, message: '' },
        updEconomicGroup: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_SUCCESS:
      return {
        ...state,
        economicGroupsToResellers: action.payload,
        loadingToResellers: false,
      }

    case types.ALL_ECONOMIC_GROUPS_TO_RESSELERS_FAILURE:
      return state

    case types.ALL_ECONOMIC_GROUPS_AUTHORIZED_REQUEST:
      return {
        ...state,
        loading: true,
        param: action.payload,
        addEconomicGroup: { ...action.payload, result: false, message: '' },
        updEconomicGroup: { ...action.payload, result: false, message: '' },
        loadingUpd: true,
        loadingAdd: true,
      }

    case types.ALL_ECONOMIC_GROUPS_AUTHORIZED_SUCCESS:
      return {
        ...state,
        economicGroupsAuthorized: action.payload,
        loading: false,
      }

    case types.ALL_ECONOMIC_GROUPS_AUTHORIZED_FAILURE:
      return state

    case types.ADD_ECONOMIC_GROUP_REQUEST:
      return { ...state, addEconomicGroup: action.payload, loadingAdd: true }

    case types.ADD_ECONOMIC_GROUP_SUCCESS:
      return { ...state, addEconomicGroup: action.payload, loadingAdd: false }

    case types.ADD_ECONOMIC_GROUP_FAILURE:
      return state

    case types.UPD_ECONOMIC_GROUP_REQUEST:
      return { ...state, updEconomicGroup: action.payload, loadingUpd: true }

    case types.UPD_ECONOMIC_GROUP_SUCCESS:
      return { ...state, updEconomicGroup: action.payload, loadingUpd: false }

    case types.EDT_ECONOMIC_GROUP_REQUEST: {
      const obj: any = action.payload
      return {
        ...state,
        editingId: obj._id,
        economicGroup: initialState.economicGroup,
        loadingUpd: false,
      }
    }

    case types.UPD_ECONOMIC_GROUP_FAILURE:
      return { ...state, updEconomicGroup: action.payload }

    case types.DEL_ECONOMIC_GROUP_REQUEST:
      return state

    case types.DEL_ECONOMIC_GROUP_SUCCESS:
      return { ...state }

    case types.DEL_ECONOMIC_GROUP_FAILURE:
      return state

    default:
      return { ...state }
  }
}
export default economicGroupReducer
