import { createSelector } from 'reselect'
import { NewProfilesTypescript, MenusTypescript } from '../../../typescript'

// Getters
const getNewProfiles = (state: {
  newProfiles: { newProfiles: NewProfilesTypescript[] }
}) => state.newProfiles.newProfiles
const getNewProfilesTree = (state: {
  newProfiles: { newProfilesTree: MenusTypescript[] }
}) => state.newProfiles.newProfilesTree
const addNewProfile = (state: {
  newProfiles: { addNewProfile: NewProfilesTypescript }
}) => state.newProfiles.addNewProfile
const updNewProfile = (state: {
  newProfiles: { updNewProfile: NewProfilesTypescript }
}) => state.newProfiles.updNewProfile
const getNewProfile = (state: {
  newProfiles: { newProfile: NewProfilesTypescript }
}) => state.newProfiles.newProfile

// Loadings
const getLoading = (state: { newProfiles: { loading: boolean } }) =>
  state.newProfiles.loading
const getLoadingTree = (state: { newProfiles: { loadingTree: boolean } }) =>
  state.newProfiles.loadingTree
const addLoading = (state: { newProfiles: { loadingAdd: boolean } }) =>
  state.newProfiles.loadingAdd
const updLoading = (state: { newProfiles: { loadingUpd: boolean } }) =>
  state.newProfiles.loadingUpd
const getOneLoading = (state: { newProfiles: { loadingOne: boolean } }) =>
  state.newProfiles.loadingOne

// exports
export const getNewProfilesSelector = createSelector(
  getNewProfiles,
  getLoading,
  (newProfiles: NewProfilesTypescript[]) => newProfiles,
)
export const getNewProfilesTreeSelector = createSelector(
  getNewProfilesTree,
  getLoading,
  (newProfilesTree: MenusTypescript[]) => newProfilesTree,
)
export const getNewProfileSelector = createSelector(
  getNewProfile,
  getOneLoading,
  (profile: NewProfilesTypescript) => profile,
)
export const addNewProfileSelector = createSelector(
  addNewProfile,
  addLoading,
  (addNewProfile: NewProfilesTypescript) => addNewProfile,
)
export const updNewProfileSelector = createSelector(
  updNewProfile,
  updLoading,
  (updNewProfile: NewProfilesTypescript) => updNewProfile,
)

// Exports Loadings
export const getLoadingNewProfilesSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingTreeNewProfilesSelector = createSelector(
  getLoadingTree,
  (loadingTree: boolean) => loadingTree,
)
export const getLoadingNewProfileSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddNewProfileSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdNewProfileSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
