import { all } from 'redux-saga/effects'

import dashboardCards from './dashboardCards'
import branches from './branches'
import devices from './devices'
import departments from './departments'
import temperatures from './temperatures'
import securityPositions from './securityPositions'
import positions from './positions'
import login from './login'
import economicGroups from './economicGroups'
import deviceTypes from './deviceTypes'
import reselers from './resellers'
import users from './users'
import profiles from './profiles'
import newProfiles from './newProfiles'
import commands from './commands'
import eventTypes from './eventTypes'
import reportsTemperatureDetails from './reports/temperatureDetails'
import allSystemLogs from './logs/system'
import menus from './menus'
import sections from './sections'
import weathers from './weathers'

export default function* rootSaga(): Generator<any> {
  return yield all([
    dashboardCards,
    branches,
    departments,
    devices,
    deviceTypes,
    temperatures,
    securityPositions,
    positions,
    economicGroups,
    login,
    reselers,
    users,
    profiles,
    newProfiles,
    commands,
    reportsTemperatureDetails,
    allSystemLogs,
    eventTypes,
    menus,
    departments,
    weathers,
    sections,
  ])
}
