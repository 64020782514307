export const ALL_BRANCHES_REQUEST = 'ALL_BRANCHES_REQUEST'
export const ALL_BRANCHES_SUCCESS = 'ALL_BRANCHES_SUCCESS'
export const ALL_BRANCHES_FAILURE = 'ALL_BRANCHES_FAILURE'

export const ONE_BRANCHE_REQUEST = 'ONE_BRANCHE_REQUEST'
export const ONE_BRANCHE_SUCCESS = 'ONE_BRANCHE_SUCCESS'
export const ONE_BRANCHE_FAILURE = 'ONE_BRANCHE_FAILURE'
export const EDT_BRANCHE_REQUEST = 'EDT_BRANCHE_REQUEST'

export const ALL_BRANCHES_BY_GROUP_REQUEST = 'ALL_BRANCHES_BY_GROUP_REQUEST'
export const ALL_BRANCHES_BY_GROUP_SUCCESS = 'ALL_BRANCHES_BY_GROUP_SUCCESS'
export const ALL_BRANCHES_BY_GROUP_FAILURE = 'ALL_BRANCHES_BY_GROUP_FAILURE'
export const ALL_BRANCHES_BY_USER_REQUEST = 'ALL_BRANCHES_BY_USER_REQUEST'
export const ALL_BRANCHES_BY_USER_SUCCESS = 'ALL_BRANCHES_BY_USER_SUCCESS'
export const ALL_BRANCHES_BY_USER_FAILURE = 'ALL_BRANCHES_BY_USER_FAILURE'

export const ADD_BRANCH_REQUEST = 'ADD_BRANCH_REQUEST'
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS'
export const ADD_BRANCH_FAILURE = 'ADD_BRANCH_FAILURE'
export const EDT_BRANCH_REQUEST = 'EDT_BRANCH_REQUEST'
export const DEL_BRANCH_REQUEST = 'DEL_BRANCH_REQUEST'
export const DEL_BRANCH_SUCCESS = 'DEL_BRANCH_SUCCESS'
export const DEL_BRANCH_FAILURE = 'DEL_BRANCH_FAILURE'
export const UPD_BRANCH_REQUEST = 'UPD_BRANCH_REQUEST'
export const UPD_BRANCH_SUCCESS = 'UPD_BRANCH_SUCCESS'
export const UPD_BRANCH_FAILURE = 'UPD_BRANCH_FAILURE'
