import { createSelector } from 'reselect'
import { DepartmentsTypescript } from '../../../typescript'

// Getters
const getDepartments = (state: {
  departments: { departments: DepartmentsTypescript[] }
}) => state.departments.departments
const addDepartment = (state: {
  departments: { addDepartment: DepartmentsTypescript }
}) => state.departments.addDepartment
const updDepartment = (state: {
  departments: { updDepartment: DepartmentsTypescript }
}) => state.departments.updDepartment
const getDepartment = (state: {
  departments: { department: DepartmentsTypescript }
}) => state.departments.department

// Loadings
const getLoading = (state: { departments: { loading: boolean } }) =>
  state.departments.loading
const addLoading = (state: { departments: { loadingAdd: boolean } }) =>
  state.departments.loadingAdd
const updLoading = (state: { departments: { loadingUpd: boolean } }) =>
  state.departments.loadingUpd
const getOneLoading = (state: { departments: { loadingOne: boolean } }) =>
  state.departments.loadingOne

// exports
export const getDepartmentsSelector = createSelector(
  getDepartments,
  getLoading,
  (departments: DepartmentsTypescript[]) => departments,
)
export const getDepartmentSelector = createSelector(
  getDepartment,
  getOneLoading,
  (department: DepartmentsTypescript) => department,
)
export const addDepartmentSelector = createSelector(
  addDepartment,
  addLoading,
  (addDepartment: DepartmentsTypescript) => addDepartment,
)
export const updDepartmentSelector = createSelector(
  updDepartment,
  updLoading,
  (updDepartment: DepartmentsTypescript) => updDepartment,
)

// Exports Loadings
export const getLoadingDepartmentsSelector = createSelector(
  getLoading,
  (loading: boolean) => loading,
)
export const getLoadingDepartmentSelector = createSelector(
  getOneLoading,
  (loading: boolean) => loading,
)
export const getLoadingAddDepartmentSelector = createSelector(
  addLoading,
  (loadingAdd: boolean) => loadingAdd,
)
export const getLoadingUpdDepartmentSelector = createSelector(
  updLoading,
  (loadingUpd: boolean) => loadingUpd,
)
